import { useApolloClient } from "@apollo/client";
import { useEffect, useState } from "react";
import {
  GetFilesNotification,
  useGetFilesNotification,
} from "../../graphql-generated";
import styles from "./RefreshButton.module.scss";

type RefreshButtonProps = {
  folderId?: string;
};

const RefreshButton = ({ folderId }: RefreshButtonProps) => {
  const client = useApolloClient();
  const { data: notificationData } = useGetFilesNotification({
    variables: {
      filter: {
        ...(folderId ? { parent_id: { _eq: folderId } } : {}),
      },
    },
  });
  const [lastNotification, setLastNotification] = useState<
    GetFilesNotification["notificationData"] | null
  >(null);

  const notification = notificationData?.notificationData || null;

  useEffect(() => {
    if (lastNotification === null && notification)
      setLastNotification(notification);
  }, [lastNotification, notification]);

  const isChanged = lastNotification && notification !== lastNotification;

  if (!isChanged) {
    return null;
  }

  return (
    <div className={styles.root}>
      Có ảnh vừa mới được tải lên...
      <button
        className={styles.refresh}
        onClick={async () => {
          setLastNotification(notification);
          await client.reFetchObservableQueries(true);
        }}
      >
        Cập nhật
      </button>
    </div>
  );
};

export default RefreshButton;
