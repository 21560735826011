import React, { useRef, useState } from "react";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { useClickAway, useLocation } from "react-use";
import qs from "qs";
import firebase from "firebase/app";
import PhotosIcon from "../../components/Icons/PhotosIcon";
import SearchIcon from "../../components/Icons/SearchIcon";
import { SelectionProps } from "../../hooks/useSelection";
import styles from "./Header.module.scss";
import Logo from "../../components/Icons/Logo";

type HeaderProps = {
  selection?: SelectionProps;
};

const Header = ({ selection }: HeaderProps) => {
  const ref = useRef<HTMLDivElement>(null);
  useClickAway(ref, () => {
    setOpen(false);
  });
  const match = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const [keyword, setKeyword] = useState(
    qs.parse((location.search || "").slice(1))?.keyword as string
  );
  const [open, setOpen] = useState(false);

  const user = firebase.auth().currentUser;

  return (
    <header className={styles.root}>
      <div className={styles.logo}>
        <Link to="/">
          <Logo />
        </Link>
      </div>
      <div className={styles.right}>
        {!!selection?.fileIds.length && (
          <button
            className={styles.selected}
            onClick={() => history.push("/basket")}
          >
            <label>{selection.fileIds.length}</label>
            <PhotosIcon />
          </button>
        )}
        <div className={styles.profile}>
          <button
            className={styles.logout}
            onClick={async () => {
              await firebase.auth().signOut();
              localStorage.clear();
            }}
          >
            {user && user.photoURL && <img src={user.photoURL} alt="avatar" />}
            <div>
              <h4>{user?.displayName}</h4>
              <small>Đăng xuất</small>
            </div>
          </button>
        </div>
      </div>
    </header>
  );
};

export default React.memo(Header);
