import styles from "./Summary.module.css";
import {
  UploadState,
  useUploadFilesContext,
} from "../../hooks/useUploadFilesContext";

const Summary = () => {
  const { filesState } = useUploadFilesContext();
  function countState(state: UploadState) {
    return filesState.filter((s) => s.state === state).length;
  }

  return (
    <div className={styles.summary}>
      <div>
        <h3>{countState("remaining")}</h3>
        <label>Còn lại</label>
      </div>
      <div className="primary-invert">
        <h3>{countState("success")}</h3>
        <label>Hoàn thành</label>
      </div>
      <div className="error-invert">
        <h3>
          {countState("error")}/{countState("duplicated")}
        </h3>
        <label>Lỗi/Trùng</label>
      </div>
    </div>
  );
};

export default Summary;
