import { useGetFolders } from "../../graphql-generated";
import OpenLocalFolder from "../OpenLocalFolder/OpenLocalFolder";
import CreateFolder from "./CreateFolder";
import EmptyState from "./EmptyState";
import Folder from "./Folder";
import styles from "./Folders.module.css";

type FoldersProps = {
  folderId?: string;
};

const Folders = ({ folderId }: FoldersProps) => {
  const { data } = useGetFolders({
    variables: {
      parent_id: folderId
        ? { _eq: folderId }
        : {
            _is_null: true,
          },
    },
  });

  const showEmptyState = data?.folders.length === 0 && !folderId;

  return (
    <div className={styles.folders}>
      {!showEmptyState && window.showDirectoryPicker && <OpenLocalFolder />}
    </div>
  );
};

export default Folders;
