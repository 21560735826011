const khung_1 = `
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="2000" height="2000" viewBox="0 0 2000 2000">
  <defs>
    <style>
      .cls-1 {
        clip-path: url(#clip-Canvas);
      }

      .cls-2, .cls-8 {
        {{style}}
      }

      .cls-2 {
        font-size: 170px;
      }

      .cls-2, .cls-6, .cls-7 {
        font-family: UTM-HelvetIns, UTM HelvetIns;
      }

      .cls-3 {
        letter-spacing: -0.02em;
      }

      .cls-4 {
        font-size: 250px;
      }

      .cls-10, .cls-5 {
        fill: none;
      }

      .cls-5 {
        stroke: #fff;
        stroke-dasharray: 20 10;
      }

      .cls-5, .cls-6, .cls-7 {
        stroke-width: 5px;
      }

      .cls-6, .cls-7 {
        fill: #fffc00;
        stroke: #d00000;
        stroke-linejoin: round;
        letter-spacing: -0.04em;
      }

      .cls-6 {
        font-size: 363.778px;
      }

      .cls-7 {
        font-size: 134.061px;
      }

      .cls-8 {
        stroke: #707070;
      }

      .cls-9 {
        stroke: none;
      }

      .cls-11 {
        fill: rgba(0,0,0,0);
      }

      .cls-12 {
        filter: url(#_199);
      }
    </style>
    <filter id="_199" x="1404.19" y="839.021" width="555.412" height="561.202" filterUnits="userSpaceOnUse">
      <feOffset dx="-10.912" dy="11.702" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="0.5" result="blur"/>
      <feFlood flood-color="#910000" flood-opacity="0.341"/>
      <feComposite operator="in" in2="blur"/>
      <feComposite in="SourceGraphic"/>
    </filter>
    <clipPath id="clip-Canvas">
      <rect width="2000" height="2000"/>
    </clipPath>
  </defs>
  <g id="Canvas" class="cls-1">
    <rect class="cls-11" width="2000" height="2000"/>
    <text id="ĐỒNG_GIÁ" data-name="ĐỒNG
GIÁ" class="cls-2" transform="translate(1704.303 603.251)"><tspan class="cls-3"><tspan x="-188.516" y="0">ĐỒNG</tspan></tspan><tspan class="cls-4"><tspan x="-173.645" y="263.479">GIÁ</tspan></tspan></text>
    <g id="Rectangle_2" data-name="Rectangle 2" class="cls-5" transform="translate(1478 371)">
      <rect class="cls-9" width="479" height="563" rx="20"/>
      <rect class="cls-10" x="2.5" y="2.5" width="474" height="558" rx="17.5"/>
    </g>
    <g class="cls-12" transform="matrix(1, 0, 0, 1, 0, 0)">
      <text id="_199-2" data-name="199" class="cls-6" transform="matrix(1, 0, 0, 1, 1421.6, 844.02)"><tspan text-anchor="end" x="530.436" y="441">000</tspan></text>
    </g>
    <text id="K" class="cls-7" transform="translate(1952.366 1285.107)"><tspan x="-37.247" y="0">K</tspan></text>
  </g>
</svg>

`;

export default khung_1;
