import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  split,
} from "@apollo/client";
import { WebSocketLink } from "@apollo/client/link/ws";
import { setContext } from "@apollo/client/link/context";
import { getMainDefinition } from "@apollo/client/utilities";
import firebase from "firebase/app";

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URI,
});

const authLink = setContext(async (_operator, { headers }) => {
  const token = await firebase.auth().currentUser?.getIdToken();
  return {
    headers: {
      ...headers,
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
    },
  };
});

const createWebsocketLink = () =>
  new WebSocketLink({
    uri: process.env.REACT_APP_GRAPHQL_SUBSCRIPTION_URI as string,
    options: {
      lazy: true,
      reconnect: true,
      connectionParams: async (_: any) => {
        const token = await firebase.auth().currentUser?.getIdToken();
        return {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
    },
  });

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  createWebsocketLink(),
  authLink.concat(httpLink)
);

const apolloClient = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          file_by_pk: {
            read(_, { args, toReference }) {
              return toReference({
                __typename: "file",
                id: args?.id,
              });
            },
          },
          theme_by_pk: {
            read(_, { args, toReference }) {
              return toReference({
                __typename: "theme",
                id: args?.id,
              });
            },
          },
        },
      },
    },
  }),
});

export default apolloClient;
