import ReactDOM from "react-dom/server";
import {
  fitContainer,
  loadImage,
  resolvePhotoUrlAsBase64,
} from "../utils/imageUtils";
import { SVGViewer } from "../containers/SVGEditor";
import { getDirectoryHandle } from "./useLocalFolder";
import { loadSVG } from "./useSVGLoader";
import {
  FileListItem,
  Hooks_GetTheme,
  Hooks_GetThemeDocument,
  Hooks_GetThemeVariables,
} from "../graphql-generated";
import { apolloClient } from "../config";
import { DEFAULT_THEME } from "../utils/constants";
import { useAsyncFn } from "react-use";
import { useCallback } from "react";

async function getTheme(id: string) {
  if (!id) {
    return DEFAULT_THEME;
  }

  const { data } = await apolloClient.query<
    Hooks_GetTheme,
    Hooks_GetThemeVariables
  >({
    query: Hooks_GetThemeDocument,
    variables: {
      id,
    },
  });

  return data.theme || DEFAULT_THEME;
}

export function getDownloadFileName(fileName: string, format: string) {
  const ext = format.replace("image/", "");
  const name = fileName.replace(/\.[^.]+$/, "");
  return `${name}.${ext}`;
}

function useDownloadFile() {
  const [{ loading }, toCanvas] = useAsyncFn(
    async (file: FileListItem, resolution: string) => {
      const dirHandle = await getDirectoryHandle(file.parent_id);
      const fileHandle = await dirHandle?.getFileHandle(file.url.slice(6));
      const f = await fileHandle?.getFile();
      if (f) {
        const url = await resolvePhotoUrlAsBase64(
          file.parent_id,
          file.url,
          "2048"
        );
        const theme = await getTheme(file.theme_id);
        const { layers, size } = await loadSVG(url, theme, file.meta);

        if (size) {
          const source = ReactDOM.renderToString(
            <SVGViewer layers={layers} size={size} originalSize={size} />
          );
          const base64 = Buffer.from(source).toString("base64");
          const image = await loadImage(`data:image/svg+xml;base64,${base64}`);
          const canvas = document.createElement("canvas");
          const canvasSize = fitContainer(size, {
            width: parseInt(resolution),
            height: Number.MAX_SAFE_INTEGER,
          });
          Object.assign(canvas, canvasSize);

          const ctx = canvas.getContext("2d");
          ctx?.drawImage(
            image,
            0,
            0,
            size.width,
            size.height,
            0,
            0,
            canvasSize.width,
            canvasSize.height
          );
          return {
            canvas,
            cleanUp: () => {
              canvas.remove();
              image.remove();
            },
          };
        }
      }

      return {
        canvas: null,
        cleanUp: () => {},
      };
    },
    []
  );

  const writeToLocalFile = async (
    canvas: HTMLCanvasElement,
    folderId: string,
    fileName: string,
    format: string
  ) => {
    const dirHandle = await getDirectoryHandle(folderId);
    if (dirHandle) {
      const out = await dirHandle.getDirectoryHandle("out", {
        create: true,
      });

      const fileHandle = await out.getFileHandle(fileName, {
        create: true,
      });

      const w = await fileHandle.createWritable();
      canvas.toBlob(
        async (blob) => {
          await w?.write(blob);
          await w?.close();
        },
        format,
        1
      );
    }
  };

  const downloadFile = useCallback(
    (
      canvas: HTMLCanvasElement,
      folderId: string,
      fileName: string,
      format: string
    ) => {
      canvas.toBlob((blob) => {
        if (blob) {
          const url = URL.createObjectURL(blob);

          const a = document.createElement("a");
          document.body.appendChild(a);
          const blobUrl = window.URL.createObjectURL(blob);
          a.href = blobUrl;
          a.download = fileName;
          a.click();

          setTimeout(() => {
            URL.revokeObjectURL(url);
            document.body.removeChild(a);
          }, 0);
        }
      }, format);
    },
    []
  );

  return { loading, writeToFile: writeToLocalFile, downloadFile, toCanvas };
}

export default useDownloadFile;
