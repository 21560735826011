import { useCallback } from "react";
import { v4 } from "uuid";
import { SyncLocalFiles, useSyncLocalFiles } from "../../graphql-generated";
import { isFileSupported, parseTitle } from "../../utils/utils";

export type LocalFolder = SyncLocalFiles["folder"];

const useLocalFolder = () => {
  const [syncFolder] = useSyncLocalFiles();
  const open = useCallback(
    async (
      dirHandle: FileSystemDirectoryHandle,
      projectId?: string
    ): Promise<LocalFolder> => {
      const files = [];
      for await (let fileHandle of dirHandle.values()) {
        if (fileHandle.kind === "file" && isFileSupported(fileHandle.name)) {
          const file = await fileHandle.getFile();

          files.push({
            name: fileHandle.name,
            type: file.type,
            url: `local:${fileHandle.name}`,
            meta: { title: parseTitle(file.name) },
          });
        }
      }

      const { data } = await syncFolder({
        variables: {
          id: projectId || v4(),
          name: `local:${dirHandle.name}`,
          files: files,
        },
      });

      return data?.folder;
    },
    [syncFolder]
  );

  return { open };
};

export default useLocalFolder;
