import { useApolloClient } from "@apollo/client";
import { useFormik } from "formik";
import { useCallback, useMemo, useState } from "react";
import xlsx from "xlsx";
import CloseIcon from "../../components/Icons/CloseIcon";
import { useFilesByIds, useUpdateFileMeta } from "../../graphql-generated";
import styles from "./EditFilesModal.module.scss";
import Loading from "./Loading";
import Row from "./Row";

type EditFilesModalProps = {
  fileIds: Array<string>;
  onClose(): void;
};

const SHEET_NAME = "Thông tin sản phẩm";

const EditFilesModal = ({ fileIds, onClose }: EditFilesModalProps) => {
  const { loading, data } = useFilesByIds({
    variables: {
      ids: fileIds,
    },
  });
  const [updateFileMeta] = useUpdateFileMeta();
  const client = useApolloClient();
  const [processing, setProcessing] = useState(false);

  const files = useMemo(() => data?.files || [], [data]);
  const form = useFormik({
    initialValues: useMemo(() => {
      return (
        data?.files?.map((file) => ({
          id: file.id,
          fileName: file.name,
          name: file.meta?.name || "",
          price: file.meta?.price || "",
          description: file.meta?.description || "",
        })) || []
      );
    }, [data]),
    enableReinitialize: true,
    onSubmit: async (values) => {
      setProcessing(true);
      for (let row of values) {
        if (row.name || row.price || row.description) {
          await updateFileMeta({
            variables: {
              id: row.id,
              meta: {
                name: row.name,
                price: row.price,
                description: row.description,
              },
            },
          });
        }
      }
      setProcessing(false);
      await client.reFetchObservableQueries();
      onClose();
    },
  });

  const toExcel = useCallback(() => {
    const book = xlsx.utils.book_new();
    const sheet = xlsx.utils.aoa_to_sheet([
      [
        "Vui lòng nhập thông tin sản phẩm vào bảng dưới đây. Sử dụng chức năng Nạp excel và Lưu lại trên Magikpic để cập nhật thông tin sản phẩm.",
      ],
      [],
      [
        "Mã sản phẩm (không xoá)",
        "File ảnh",
        "Tên sản phẩm",
        "Giá sản phẩm",
        "Chất liệu công năng",
      ],
      ...form.values.map((file) => [
        file.id,
        file.fileName,
        file.name,
        file.price,
        file.description,
      ]),
    ]);
    sheet["!margins"] = {
      left: 0.7,
      right: 0.7,
      top: 0.75,
      bottom: 0.75,
      header: 0.3,
      footer: 0.3,
    };
    sheet["!cols"] = [
      { wch: 40 },
      { wch: 40 },
      { wch: 30 },
      { wch: 20 },
      { wch: 100 },
    ];
    xlsx.utils.book_append_sheet(book, sheet, SHEET_NAME);
    xlsx.writeFile(book, "thong tin san pham.xlsx");
  }, [form.values]);

  const fromExcel = useCallback(
    async (file: File) => {
      const book = xlsx.read(await file.arrayBuffer(), { type: "array" });
      const sheet = book.Sheets[SHEET_NAME];
      const values = form.values;
      for (let i = 4; !!sheet["A" + i]; i++) {
        const id = sheet["A" + i]?.w || "";
        const name = sheet["C" + i]?.w || "";
        const price = sheet["D" + i]?.w || "";
        const description = sheet["E" + i]?.w || "";
        const index = files.findIndex((file) => file.id === id);
        values[index].name = name;
        values[index].price = price;
        values[index].description = description;
      }
      form.setValues(values);
    },
    [files, form]
  );

  if (loading) {
    return <Loading />;
  }

  if (!form?.values?.[0]) {
    return <Loading />;
  }

  if (processing) {
    return (
      <div className="backdrop">
        <div className={styles.root}>
          <h2>Bổ sung thông tin ảnh</h2>
          <div className={styles.contentEmpty}>
            <h3>Đang lưu trữ thông tin ảnh...</h3>
            <div className={styles.loading}>
              {/* @ts-ignore */}
              <lottie-player
                autoplay
                loop
                mode="normal"
                src="/assets/kitten.json"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="backdrop" onClick={onClose}>
      <div className={styles.root} onClick={(e) => e.stopPropagation()}>
        <div className="flex-lr">
          <h2>Bổ sung thông tin ảnh</h2>
          <div className="buttons">
            <button className="secondary border button" onClick={toExcel}>
              Tải mẫu excel
            </button>
            <button
              className="secondary border button"
              onClick={() => document.getElementById("uploadExcel")?.click()}
            >
              Nạp excel
            </button>
            <input
              id="uploadExcel"
              style={{ display: "none" }}
              type="file"
              onChange={(e) => {
                const file = e.currentTarget.files?.[0];
                file && fromExcel(file);
              }}
            />
            <button className="primary border button" onClick={form.submitForm}>
              Lưu lại
            </button>
            <button className={styles.close} onClick={onClose}>
              <CloseIcon />
            </button>
          </div>
        </div>
        <div className={styles.content}>
          {files.map((file, index) => (
            <Row key={file.id} rowIndex={index} form={form} file={file} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default EditFilesModal;
