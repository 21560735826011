import { FormikProps } from "formik";
import { useState } from "react";
import { useEvent, useThrottleFn } from "react-use";
import get from "lodash/get";
import styles from "./SmartSlider.module.scss";

type SmartSliderProps = {
  label: string;
  path: string;
  min: number;
  max: number;
  step: number;
  form: FormikProps<any>;
};

const SmartSlider = ({
  label,
  path,
  form,
  min,
  max,
  step,
}: SmartSliderProps) => {
  const [dragging, setDragging] = useState(false);
  const [throttledValue, setThrottledValue] = useState<number>(
    get(form.values, path)
  );

  useThrottleFn(
    (throttledValue) => {
      form.setFieldValue(path, Math.max(Math.min(throttledValue, max), min));
    },
    50,
    [throttledValue]
  );

  useEvent(
    "mouseup",
    () => {
      setDragging(false);
    },
    global.document.body
  );

  return (
    <div className="form-item">
      <label>{label}</label>
      <div className={styles.flex}>
        <div
          className={styles.root}
          onMouseMove={(e) => {
            if (dragging) {
              const { x, width } = e.currentTarget.getBoundingClientRect();
              const ratio = (e.clientX - x + 1 - 16) / (width - 32);
              const value = parseFloat(
                (Math.round((ratio * (max - min)) / step) * step + min).toFixed(
                  5
                )
              );
              setThrottledValue(value);
            }
          }}
          onMouseDown={(e) => {
            const { x, width } = e.currentTarget.getBoundingClientRect();
            const ratio = (e.clientX - x + 1 - 16) / (width - 32);
            const value = parseFloat(
              (Math.round((ratio * (max - min)) / step) * step + min).toFixed(5)
            );
            setThrottledValue(value);
          }}
        >
          <div className={styles.rule}>
            <div
              className={styles.current}
              style={{
                left: `${Math.min(
                  ((get(form.values, path) - min) / (max - min)) * 100,
                  max
                )}%`,
              }}
              onMouseDown={() => {
                setDragging(true);
              }}
            />
          </div>
        </div>
        <input
          type="number"
          value={get(form.values, path)}
          name={path}
          step={step}
          onChange={form.handleChange}
        />
      </div>
    </div>
  );
};

export default SmartSlider;
