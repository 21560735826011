import styles from "./Loading.module.scss"

const LoadingScreen = () => {
  return (
    <div className={styles.root}>
      {/* @ts-ignore */}
      <lottie-player autoplay loop mode="normal" src="/assets/kitten.json" />
    </div>
  );
};

export default LoadingScreen;
