import { useCallback } from "react";
import { createStateContext } from "react-use";

export type SelectionProps = {
  fileIds: Array<string>;
  selecting: boolean;
};

const [useSelectionContext, SelectionProvider] =
  createStateContext<SelectionProps>({
    fileIds: [],
    selecting: false,
  });

type UseSelectionContextResult = ReturnType<typeof useSelectionContext>;

const useSelection = ([selection, setSelection]: UseSelectionContextResult) => {
  const clearSelection = useCallback(() => {
    setSelection((selection) => ({
      ...selection,
      fileIds: [],
    }));
  }, [setSelection]);

  const hide = useCallback(() => {
    setSelection((selection) => ({
      ...selection,
      selecting: false,
    }));
  }, [setSelection]);

  const show = useCallback(() => {
    setSelection((selection) => ({
      ...selection,
      selecting: true,
    }));
  }, [setSelection]);

  const select = useCallback(
    (ids: Array<string>) => {
      setSelection((selection) => ({
        ...selection,
        fileIds: ids,
      }));
    },
    [setSelection]
  );

  const toggle = useCallback(
    (id: string) => {
      setSelection((selection) => {
        const selected = selection.fileIds.includes(id);
        return selected
          ? {
              ...selection,
              fileIds: selection.fileIds.filter((fileId) => fileId !== id),
            }
          : { ...selection, fileIds: [...selection.fileIds, id] };
      });
    },
    [setSelection]
  );

  return { selection, clearSelection, select, toggle, hide, show };
};

export { useSelection, useSelectionContext };

export default SelectionProvider;
