import { useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router";
import BackIcon2 from "../../components/Icons/BackIcon2";
import { RequestPermission } from "../../containers/RequestPermission";
import {
  getDirectoryHandle,
  useLocalFolderContext,
} from "../../hooks/useLocalFolder";
import styles from "./LocalFolderScreenLoader.module.scss";
import LocalFolderSkeleton from "./LocalFolderSkeleton";
import { EMPTY_ARRAY } from "../../utils/constants";
import Searching from "../../components/Lotties/Searching";
import { useAsync } from "react-use";
import { isFileSupported } from "../../utils/utils";
import LocalFolderScreen from "./LocalFolderScreen";

const FolderScreenLoader = () => {
  const history = useHistory();
  const match = useRouteMatch<{ folderId: string }>();
  const folderId = match.params.folderId;
  const { state, loadLocalFolder } = useLocalFolderContext();

  useEffect(() => {
    loadLocalFolder(folderId);
  }, [folderId, loadLocalFolder]);

  const { loading: loadingFiles, value } = useAsync(async () => {
    if (state === "ready") {
      const dirHandle = await getDirectoryHandle(folderId);
      if (dirHandle) {
        const files = [];
        for await (let fileHandle of dirHandle.values()) {
          if (fileHandle.kind === "file" && isFileSupported(fileHandle.name)) {
            files.push(fileHandle.name);
          }
        }
        return files;
      }
    }

    return [];
  }, [state, folderId]);

  const fileNames = value || EMPTY_ARRAY;

  if (loadingFiles) {
    return (
      <LocalFolderSkeleton>
        <div className={styles.emptyState}>
          <Searching />
        </div>
      </LocalFolderSkeleton>
    );
  }

  if (state === "denied" || state === "asking") {
    return (
      <LocalFolderSkeleton>
        <RequestPermission folderId={folderId} />
      </LocalFolderSkeleton>
    );
  }

  if (!fileNames.length) {
    return (
      <LocalFolderSkeleton>
        <div className={styles.emptyState}>
          <h3>Thư mục chưa có ảnh, vui lòng chọn thư mục khác.</h3>
          <div className={styles.buttons}>
            <button className={styles.back} onClick={history.goBack}>
              <BackIcon2 /> Quay lại
            </button>
          </div>
        </div>
      </LocalFolderSkeleton>
    );
  }

  return <LocalFolderScreen fileNames={fileNames} />;
};

export default FolderScreenLoader;
