import { useHistory } from "react-router";
import OpenLinkIcon from "../../components/Icons/OpenLinkIcon";
import { Files } from "../../containers/Files";
import { Folders } from "../../containers/Folders";
import { Header } from "../../containers/Header";
import { useSearchRemoteFiles } from "../../graphql-generated";
import { useSelectionContext } from "../../hooks/useSelection";
import { EMPTY_ARRAY } from "../../utils/constants";
import styles from "./HomeScreen.module.scss";

const HomeScreen = () => {
  const [selection] = useSelectionContext();

  return (
    <div>
      <Header selection={selection} />
      <div className={styles.body}>
        <Folders />
      </div>
    </div>
  );
};

export default HomeScreen;
