import React from "react";
import { Header } from "../../containers/Header";
import styles from "./LocalFolderSkeleton.module.scss";

type LocalFolderSkeletonProps = {
  folderName?: string;
  refreshComponent?: React.ReactNode;
  actionComponent?: React.ReactNode;
  modalComponent?: React.ReactNode;
  children: React.ReactNode;
};

const LocalFolderSkeleton = ({
  folderName,
  refreshComponent,
  actionComponent,
  modalComponent,
  children,
}: LocalFolderSkeletonProps) => {
  return (
    <div>
      <Header />
      <div className={styles.body}>
        <div className={styles.section}>
          <div className="flex-lr">
            <div className={styles.title}>
              {folderName && (
                <h2>
                  <ul className="breadcrumb">
                    <li>Bộ sưu tập</li>
                    <li>{folderName}</li>
                  </ul>
                </h2>
              )}
            </div>
            <div className="flex-spacer">{refreshComponent}</div>
            <div className="buttons">{actionComponent}</div>
          </div>
          {children}
        </div>
      </div>
      {modalComponent}
    </div>
  );
};

export default LocalFolderSkeleton;
