const MoreIcon = () => (
  <svg version="1.1" viewBox="0 0 28 122.88">
    <path
      fill="currentColor"
      d="M15,0A15,15,0,1,1,0,15,15,15,0,0,1,15,0Zm0,92.93a15,15,0,1,1-15,15,15,15,0,0,1,15-15Zm0-46.47a15,15,0,1,1-15,15,15,15,0,0,1,15-15Z"
    />
  </svg>
);

export default MoreIcon;
