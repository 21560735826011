const ReplaceIcon = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 122.88 110.76"
  >
    <path
      fill="currentColor"
      d="M37.21,15.38l0,0.17h33.53v26.54l-33.79,0l0.01,0.26v9.61c0,0.08,0,0.16-0.01,0.24c-0.09,2.07-0.77,3.54-2.06,4.39 c-1.29,0.86-2.99,0.94-5.08,0.23c-0.23-0.08-0.43-0.19-0.61-0.33C19.1,48.57,12.14,40.65,2.04,32.72L1.9,32.6 c-1.68-1.52-2.14-3.11-1.79-4.7c0.31-1.4,1.29-2.6,2.6-3.63L27.47,2.3c1.21-0.95,2.48-1.69,3.67-2.05c1.07-0.32,2.11-0.35,3.07,0 c1.05,0.38,1.88,1.15,2.42,2.39c0.38,0.89,0.59,2.03,0.59,3.46v9.05C37.22,15.24,37.22,15.31,37.21,15.38L37.21,15.38L37.21,15.38z M85.67,82.73l0-0.17H52.14V56.03l33.79,0l-0.01-0.26v-9.61c0-0.08,0-0.17,0.01-0.24c0.09-2.07,0.77-3.54,2.06-4.39 c1.29-0.86,2.99-0.94,5.08-0.23c0.23,0.08,0.43,0.19,0.61,0.33c10.1,7.92,17.06,15.85,27.15,23.78l0.14,0.12 c1.68,1.52,2.14,3.11,1.79,4.7c-0.31,1.4-1.29,2.6-2.6,3.63L95.41,95.82c-1.21,0.95-2.48,1.69-3.67,2.05 c-1.07,0.32-2.11,0.35-3.07,0c-1.05-0.38-1.88-1.15-2.42-2.39c-0.38-0.89-0.59-2.03-0.59-3.46v-9.06 C85.66,82.88,85.66,82.81,85.67,82.73L85.67,82.73L85.67,82.73z"
    />
  </svg>
);

export default ReplaceIcon;
