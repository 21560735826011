import { FormikProps } from "formik";
import get from "lodash/get";
import { useMemo } from "react";

type SmartDropdownProps = {
  label: string;
  path: string;
  form: FormikProps<any>;
  items: Array<[string, string]>;
};

const SmartDropdown = ({ label, path, form, items }: SmartDropdownProps) => (
  <div className="form-item">
    <label htmlFor="logo-size">{label}</label>
    <select
      className="full-w"
      name={path}
      value={get(form.values, path)}
      onChange={form.handleChange}
    >
      {useMemo(
        () =>
          items.map(([value, text]) => (
            <option key={`o-${value}`} value={value}>
              {text}
            </option>
          )),
        [items]
      )}
    </select>
  </div>
);

export default SmartDropdown;
