import { useCallback } from "react";
import { createStateContext } from "react-use";

type DialogArguments = Record<string, any>;

type DialogState = {
  name: string;
  args?: DialogArguments;
};

const [useDialogStateInternal, DialogStateProvider] =
  createStateContext<DialogState>({
    name: "",
  });

const useDialogState = () => {
  const [dialogState, setDialogState] = useDialogStateInternal();

  const open = useCallback(
    (name: string, args?: DialogArguments) => {
      setDialogState({ name, args });
    },
    [setDialogState]
  );

  const close = useCallback(() => {
    setDialogState({ name: "" });
  }, [setDialogState]);

  return { ...dialogState, open, close };
};

export { useDialogState, DialogStateProvider };
