import { useHooks_GetTheme } from "../graphql-generated";
import { DEFAULT_THEME } from "../utils/constants";

const useGetTheme = (themeId: string | undefined | null) => {
  const { data, loading } = useHooks_GetTheme({
    variables: { id: themeId },
    skip: !themeId,
  });
  return { theme: data?.theme || (!loading ? DEFAULT_THEME : null), loading };
};

export default useGetTheme;
