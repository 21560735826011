import firebase from "firebase/app";
import { Link } from "react-router-dom";
import DownloadIcon from "../../components/Icons/DownloadIcon";
import EyeIcon from "../../components/Icons/EyeIcon";
import { GetDownload } from "../../graphql-generated";
import styles from "./Download.module.scss";

type DownloadProps = {
  download: GetDownload["download"];
};

function count(downloadData: DownloadData, state: DownloadState) {
  return downloadData.files.filter((d) => d.state === state).length;
}

const Download = ({ download }: DownloadProps) => {
  if (!download) {
    return null;
  }

  const downloadData = download.download_data as DownloadData;

  const processing = count(downloadData, "processing");
  const completed = count(downloadData, "completed");
  const queue = count(downloadData, "queue");
  const error = count(downloadData, "error");

  const total = processing + completed + queue + error;

  return (
    <div key={download.id} className={styles.download}>
      <div className="flex-lr">
        {download.id}
        <h3>
          {completed}/{total}
        </h3>
      </div>
      <div className={styles.progress}>
        <div
          className="success"
          style={{ width: `${(completed * 100) / total}%` }}
        />
      </div>
      <div className="flex-lr">
        <div className={styles.buttons}>
          {downloadData.link && (
            <button
              className={styles.action}
              onClick={async () => {
                const token = await firebase.auth().currentUser?.getIdToken();
                const rs = await fetch(downloadData.link!, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                });
                const json = await rs.json();
                window.open(json.downloadLink, "_self");
              }}
            >
              <DownloadIcon /> Tải về
            </button>
          )}
          <Link to={`/download/${download.id}`} className={styles.action}>
            <EyeIcon /> Chi tiết
          </Link>
          {!!error && <>{error} ảnh bị lỗi</>}
        </div>
        {download.download_data?.format} - {download.download_data?.resolution}
      </div>
    </div>
  );
};

export default Download;
