import { useState } from "react";
import DownloadIcon from "../../components/Icons/DownloadIcon";
import { OutputFormat } from "../OutputFormat";
import styles from "./DownloadButton.module.scss";

type DownloadButtonProps = {
  loading: boolean;
  onDownload(format: string, resolution: string): Promise<void>;
};

const DownloadButton = ({ loading, onDownload }: DownloadButtonProps) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      {open && (
        <OutputFormat
          loading={loading}
          onCancel={() => setOpen(false)}
          onDownload={async (format, resolution) => {
            await onDownload(format, resolution);
            setOpen(false);
          }}
        />
      )}

      <button
        className={styles.download}
        onClick={() => {
          setOpen(true);
        }}
      >
        <DownloadIcon /> Tải ảnh đã chọn
      </button>
    </>
  );
};

export default DownloadButton;
