import { Header } from "../../containers/Header";
import styles from "./ActivatingScreen.module.scss";

const ActivatingScreen = () => {
  return (
    <div>
      <Header />
      <div className={styles.root}>
        <h2>Tài khoản đang chờ phê duyệt</h2>
        <span>
          Chúng tôi sẽ liên hệ với bạn sau khi tài khoản này được kích hoạt.
        </span>
        <div className="buttons">
          <button
            className="primary button"
            onClick={() => {
              window.location.reload();
            }}
          >
            Kiểm tra
          </button>
          <button
            className="error-invert border button"
            onClick={() => {
              window.location.reload();
            }}
          >
            Đăng xuất
          </button>
        </div>
      </div>
    </div>
  );
};

export default ActivatingScreen;
