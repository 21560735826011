import { useState } from "react";
import { useHistory, useRouteMatch } from "react-router";
import { ContextMenu } from "../../components/ContextMenu";
import BackIcon2 from "../../components/Icons/BackIcon2";
import CloseIcon from "../../components/Icons/CloseIcon";
import { EditFilesModal } from "../../containers/EditFilesModal";
import { Files } from "../../containers/Files";
import { OutputFormat } from "../../containers/OutputFormat";
import { RefreshButton } from "../../containers/RefreshButton";
import { FileListItem, useLocalFolder } from "../../graphql-generated";
import { SelectionProps, useSelection } from "../../hooks/useSelection";
import Progress from "./Progress";
import styles from "./LocalFolderScreen.module.scss";
import LocalFolderSkeleton from "./LocalFolderSkeleton";
import { useDirectoryPreview } from "../../hooks/usePhotoPreviewUrl";
import { EMPTY_ARRAY } from "../../utils/constants";
import Searching from "../../components/Lotties/Searching";
import { SelectionBar } from "../../containers/SelectionBar";
import useDownloadFile, {
  getDownloadFileName,
} from "../../hooks/useDownloadFile";
import { useOutputState } from "../../containers/OutputFormat/useOutputState";
import DownloadIcon from "../../components/Icons/DownloadIcon";

type FolderScreenProps = {
  fileNames: Array<string>;
};

const FolderScreen = ({ fileNames }: FolderScreenProps) => {
  const history = useHistory();
  const match = useRouteMatch<{ folderId: string }>();
  const { selection, hide, show, toggle, clearSelection, select } =
    useSelection(
      useState<SelectionProps>({
        fileIds: [],
        selecting: false,
      })
    );
  const {
    downloadFiles,
    name,
    processing,
    processed,
    showDownloads,
    hideDownloads,
    updateProgress,
  } = useOutputState();
  const [edit, setEdit] = useState(false);
  const { toCanvas, writeToFile } = useDownloadFile();
  const folderId = match.params.folderId;
  const { data, loading } = useLocalFolder({
    variables: {
      id: folderId,
      fileNames: fileNames,
    },
  });

  const {
    loading: loadingPreview,
    total,
    current,
  } = useDirectoryPreview(folderId);

  const files: Array<FileListItem> = data?.folder?.files || EMPTY_ARRAY;

  if (loading) {
    return (
      <LocalFolderSkeleton>
        <div className={styles.emptyState}>
          <Searching />
        </div>
      </LocalFolderSkeleton>
    );
  }

  const fileIds = files.map((file) => file.id) || [];
  const folderName = (data?.folder?.name || "").replace(/^local:/, "");

  return (
    <LocalFolderSkeleton
      folderName={folderName}
      refreshComponent={<RefreshButton folderId={folderId} />}
      actionComponent={
        <>
          {selection.selecting && (
            <>
              <button className={styles.close} onClick={hide}>
                <CloseIcon /> Đã chọn xong ảnh
              </button>
              {!!selection.fileIds.length && (
                <>
                  <button
                    className="primary button"
                    onClick={async () => {
                      showDownloads(
                        files.filter((file) =>
                          selection.fileIds.includes(file.id)
                        )
                      );
                    }}
                  >
                    <DownloadIcon /> Tải ảnh đã chọn
                  </button>
                  <button
                    className="primary button"
                    onClick={async () => {
                      history.push(
                        `/file/${selection.fileIds[0]}`,
                        selection.fileIds
                      );
                    }}
                  >
                    <DownloadIcon /> Sửa ảnh đã chọn
                  </button>
                </>
              )}
            </>
          )}
          {!selection.selecting && (
            <>
              <button className={styles.back} onClick={history.goBack}>
                <BackIcon2 /> Quay lại
              </button>
              <button
                className="primary button"
                onClick={() => {
                  show();
                  clearSelection();
                }}
              >
                Chọn ảnh
              </button>
              <ContextMenu
                render={({ close }) => (
                  <>
                    <h3>Bộ sưu tập</h3>
                    <ul>
                      <li
                        onClick={() => {
                          setEdit(true);
                          close();
                        }}
                      >
                        Bổ sung thông tin ảnh
                      </li>
                      <li
                        onClick={() => {
                          showDownloads(files.map((f) => f.id));
                          close();
                        }}
                      >
                        Tải toàn bộ
                      </li>
                    </ul>
                  </>
                )}
              />
            </>
          )}
        </>
      }
      modalComponent={
        <>
          {edit && (
            <EditFilesModal fileIds={fileIds} onClose={() => setEdit(false)} />
          )}
        </>
      }
    >
      {loadingPreview && total - current > 0 && (
        <Progress current={current} total={total} />
      )}
      <Files files={files} selection={selection} onToggleSelection={toggle} />
      {downloadFiles.length > 0 && (
        <OutputFormat
          loading={processing}
          progress={
            <>
              <h3>
                {processed}/{downloadFiles.length}
              </h3>
              <p>
                Đang kết xuất tới thư mục <u>{folderName}/out</u>
              </p>
              <p>{name}</p>
            </>
          }
          onCancel={hideDownloads}
          onDownload={async (format, resolution) => {
            let processed = 0;
            updateProgress({ processing: true });
            for await (let file of downloadFiles) {
              const { canvas, cleanUp } = await toCanvas(file, resolution);

              if (canvas) {
                const name = getDownloadFileName(file.name, format);
                updateProgress({ name, processed });
                await writeToFile(canvas, folderId, name, format);
                processed++;
              }
              cleanUp();
            }
            hideDownloads();
            updateProgress({ processing: false });
          }}
        />
      )}
      <SelectionBar
        files={files}
        selection={selection}
        backetButton={false}
        onClose={hide}
        onClear={clearSelection}
        onSelectAll={() => select(files.map((f) => f.id))}
      />
    </LocalFolderSkeleton>
  );
};

export default FolderScreen;
