import CloseIcon from "../../components/Icons/CloseIcon";
import { useGetThemes } from "../../graphql-generated";
import ThemeItem from "./ThemeItem";
import styles from "./ThemeSwitchModal.module.scss";

type ThemeSwitchModalProps = {
  selectedId: string;
  onClose(): void;
  onThemeSwitch(themeId: string): void;
};

const ThemeSwitchModal = ({
  selectedId,
  onClose,
  onThemeSwitch,
}: ThemeSwitchModalProps) => {
  const { data } = useGetThemes();
  const themes = data?.themes || [];
  return (
    <div className="backdrop" onClick={onClose}>
      <div className={styles.root} onClick={(e) => e.stopPropagation()}>
        <div className="flex-lr">
          <h2>Lựa chọn mẫu kết xuất</h2>
          <button className="button secondary" onClick={onClose}>
            <CloseIcon />
          </button>
        </div>
        <div className={styles.body}>
          {themes.map((theme) => (
            <ThemeItem
              key={theme.id}
              selected={theme.id === selectedId}
              theme={theme}
              onClick={() => onThemeSwitch(theme.id)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ThemeSwitchModal;
