import { DBSchema, IDBPDatabase, openDB } from "idb";

interface MagikpicDb extends DBSchema {
  folderHandlers: {
    key: string;
    value: FileSystemDirectoryHandle;
  };
  thumbnails: {
    key: string;
    value: string;
  };
}

let db: Promise<IDBPDatabase<MagikpicDb>> | null = null;

function getIndexDb() {
  if (!db) {
    db = openDB<MagikpicDb>("magikpic-db", 2, {
      upgrade(db, oldVersion) {
        if(!oldVersion) {
          db.createObjectStore("folderHandlers");
        }

        if(oldVersion <= 1) {
          db.createObjectStore("thumbnails");
        }
      },
    });
  }

  return db;
}

export default getIndexDb;
