import { Header } from "../../containers/Header";
import styles from "./FileScreenSkeleton.module.scss";

type FileScreenSkeletonProps = {
  children: React.ReactNode;
};

const FileScreenSkeleton = ({ children }: FileScreenSkeletonProps) => (
  <div>
    <Header />
    <div className={styles.body}>{children}</div>
  </div>
);

export default FileScreenSkeleton;
