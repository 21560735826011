const ClearSelectionIcon = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 122.879 122.88"
  >
    <path
      fill="currentColor"
      d="M8.773,0h105.332c2.417,0,4.611,0.986,6.199,2.574c1.589,1.588,2.574,3.783,2.574,6.199v105.333 c0,2.416-0.985,4.61-2.574,6.199c-1.588,1.588-3.782,2.574-6.199,2.574H8.773c-2.416,0-4.611-0.986-6.199-2.574 C0.986,118.717,0,116.522,0,114.106V8.773c0-2.417,0.986-4.611,2.574-6.199S6.357,0,8.773,0L8.773,0z M92.027,57.876 c1.969,0,3.563,1.596,3.563,3.563s-1.595,3.563-3.563,3.563c-20.392,0-40.784,0-61.176,0c-1.968,0-3.563-1.595-3.563-3.563 c0-1.968,1.596-3.563,3.563-3.563C51.244,57.876,71.636,57.876,92.027,57.876L92.027,57.876z M114.105,7.129H8.773 c-0.449,0-0.859,0.186-1.159,0.485c-0.3,0.3-0.486,0.71-0.486,1.159v105.333c0,0.448,0.186,0.859,0.486,1.159 c0.3,0.299,0.71,0.485,1.159,0.485h105.332c0.449,0,0.86-0.187,1.159-0.485c0.3-0.3,0.486-0.711,0.486-1.159V8.773 c0-0.449-0.187-0.859-0.486-1.159C114.966,7.315,114.555,7.129,114.105,7.129L114.105,7.129z"
    />
  </svg>
);

export default ClearSelectionIcon;
