import { useEffect, useState } from "react";
import { Options, resolvePhotoUrl } from "../utils/imageUtils";

const useResolvedUrl = (
  folderId: string | undefined,
  url: string,
  { resolution }: Pick<Options, "resolution">
) => {
  const [resolvedUrl, setResolvedUrl] = useState("");

  useEffect(() => {
    (async function () {
      setResolvedUrl(await resolvePhotoUrl(folderId, url, resolution));
    })();
  }, [folderId, url, resolution]);

  return resolvedUrl;
};

export default useResolvedUrl;
