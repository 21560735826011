import styles from "./Loading.module.scss";

const Loading = () => (
  <div className="backdrop">
    <div className={styles.loading}>
      {/* @ts-ignore */}
      <lottie-player autoplay loop mode="normal" src="/assets/kitten.json" />
    </div>
  </div>
);

export default Loading;
