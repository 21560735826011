import React from "react";
import ReactDOM from "react-dom";
import { ApolloProvider } from "@apollo/client";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.css";
import "./styles/index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import AuthContextProvider from "./hooks/useAuth";
import { apolloClient } from "./config";
import { DialogStateProvider } from "./hooks/useDialog";
import SelectionProvider from "./hooks/useSelection";
import UploadFilesStateProvider from "./hooks/useUploadFilesContext";
import LocalFolderProvider from "./hooks/useLocalFolder";

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={apolloClient}>
      <AuthContextProvider>
        <SelectionProvider>
          <UploadFilesStateProvider>
            <Router>
              <DialogStateProvider>
                <LocalFolderProvider>
                  <App />
                </LocalFolderProvider>
              </DialogStateProvider>
            </Router>
          </UploadFilesStateProvider>
        </SelectionProvider>
      </AuthContextProvider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
