import { useEffect } from "react";
import firebase from "firebase/app";
import { createStateContext } from "react-use";
import { useApolloClient } from "@apollo/client";
import {
  GetUser,
  GetUserDocument,
  GetUserVariables,
} from "../graphql-generated";

type AuthContextProps = {
  initializing: boolean;
  user: (firebase.User & { role: string }) | null;
};

const [useAuthContextInternal, AuthContextProviderInternal] =
  createStateContext<AuthContextProps>({
    initializing: true,
    user: null,
  });

export const useAuthContext = () => {
  const [{ initializing, user }] = useAuthContextInternal();
  return { initializing, user };
};

type ProviderProps = {
  children: React.ReactElement;
};

(window as any).firebase = firebase;

const FirebaseAuthContextProvider = ({ children }: ProviderProps) => {
  const client = useApolloClient();
  const [, setAuth] = useAuthContextInternal();
  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        try {
          const { data } = await client.query<GetUser, GetUserVariables>({
            query: GetUserDocument,
            variables: {
              firebase_uid: user.uid,
            },
          });
          setAuth({
            initializing: false,
            user: {
              ...user,
              role: data.user?.[0].role || "",
            },
          });
        } catch (ex) {
          console.error(ex);
          //
        }
        return;
      }
      setAuth({
        initializing: false,
        user,
      });
    });
  }, [client, setAuth]);

  return children;
};

const AuthContextProvider = ({ children }: ProviderProps) => {
  return (
    <AuthContextProviderInternal>
      <FirebaseAuthContextProvider>{children}</FirebaseAuthContextProvider>
    </AuthContextProviderInternal>
  );
};

export default AuthContextProvider;
