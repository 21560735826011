import firebase from "firebase/app";
import styles from "./LoginScreen.module.scss";

const LoginScreen = () => {
  return (
    <div className={styles.root}>
      <h3>Vui lòng đăng nhập để truy cập vào hệ thống</h3>
      <div className={styles.loginButton}>
        <span className={styles.icon}></span>
        <span
          className={styles.buttonText}
          onClick={() => {
            const provider = new firebase.auth.GoogleAuthProvider();
            firebase.auth().signInWithRedirect(provider);
          }}
        >
          Đăng nhập với Google
        </span>
      </div>
    </div>
  );
};

export default LoginScreen;
