import firebase from "firebase/app";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCnZ0ICfOmr5Q_d2daqgXDpMaelAg8sJic",
  authDomain: "magikpic.firebaseapp.com",
  projectId: "magikpic",
  storageBucket: "magikpic.appspot.com",
  messagingSenderId: "77832403232",
  appId: "1:77832403232:web:0a3e9689b6fbb9ab113f45"
};

firebase.initializeApp(firebaseConfig);