import { useCallback, useState } from "react";
import { FileListItem } from "../../graphql-generated";

type OutputState = {
  downloadFiles: Array<FileListItem>;
  name: string;
  processing: boolean;
  processed: number;
};

export const useOutputState = () => {
  const [state, setState] = useState<OutputState>({
    downloadFiles: [],
    processing: false,
    name: "",
    processed: 0,
  });

  const showDownloads = useCallback((files: Array<FileListItem>) => {
    setState({
      downloadFiles: files,
      processing: false,
      name: "",
      processed: 0,
    });
  }, []);

  const hideDownloads = useCallback(() => {
    setState({
      downloadFiles: [],
      processing: false,
      name: "",
      processed: 0,
    });
  }, []);

  const updateProgress = useCallback((value: Partial<OutputState>) => {
    setState((state) => ({
      ...state,
      ...value,
    }));
  }, []);

  return {
    ...state,
    showDownloads,
    hideDownloads,
    updateProgress,
  };
};
