import "./config";
import "@lottiefiles/lottie-player";
import { useAuthContext } from "./hooks/useAuth";
import { LoadingScreen } from "./screens/LoadingScreen";
import { Switch, Route } from "react-router";
import { FolderScreen } from "./screens/FolderScreen";
import { CreateFolderModal } from "./containers/CreateFolderModal";
import { useDialogState } from "./hooks/useDialog";
import { HomeScreen } from "./screens/HomeScreen";
import { FilesScreen } from "./screens/FilesScreen";
import { FileScreen } from "./screens/FileScreen";
import { SelectedFilesScreen } from "./screens/SelectedFilesScreen";
import { DownloadedFilesScreen } from "./screens/DownloadedFiles";
import { UploadDrawer } from "./containers/UploadDrawer";
import { LoginScreen } from "./screens/LoginScreen";
import { ActivatingScreen } from "./screens/ActivatingScreen";
import { LocalFolderScreen } from "./screens/LocalFolderScreen";

function App() {
  const { initializing, user } = useAuthContext();
  const { name } = useDialogState();

  if (initializing) {
    console.log("initializing");
    return <LoadingScreen />;
  }

  if (!user) {
    console.log("user");
    return <LoginScreen />;
  }

  if (!user.role) {
    console.log("user.role");
    return <ActivatingScreen />;
  }
  console.log("render");
  return (
    <>
      <Switch>
        <Route path="/folder/:folderId" component={FolderScreen} />
        <Route path="/local-folder/:folderId" component={LocalFolderScreen} />
        <Route path="/file/:fileId" component={FileScreen} />
        <Route path="/download/:downloadId" component={DownloadedFilesScreen} />
        <Route path="/files" component={FilesScreen} />
        <Route path="/basket" component={SelectedFilesScreen} />
        <Route component={HomeScreen} />
      </Switch>

      {name === "create-folder" && (
        <Switch>
          <Route path="/folder/:folderId" component={CreateFolderModal} />
          <Route component={CreateFolderModal} />
        </Switch>
      )}

      <UploadDrawer />
    </>
  );
}

export default App;
