import { FormikProps } from "formik";
import get from "lodash/get";

type SmartTextAreaProps = {
  label: string;
  path: string;
  form: FormikProps<any>;
};

const SmartTextArea = ({ label, path, form }: SmartTextAreaProps) => (
  <div className="form-item">
    <label>{label}</label>
    <textarea
      className="full-w"
      name={path}
      onChange={form.handleChange}
      value={get(form.values, path)}
    />
  </div>
);

export default SmartTextArea;
