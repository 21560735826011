import { useCallback } from "react";
import firebase from "firebase/app";
import axios from "axios";
import pLimit from "p-limit";
import { useUploadFilesContext } from "../../hooks/useUploadFilesContext";
import { parseTitle } from "../../utils/utils";

async function getSignedUrl(file: File) {
  const token = await firebase.auth().currentUser?.getIdToken();
  const rs = await fetch(`${process.env.REACT_APP_API_URI}/photo/presign`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      name: file.name,
      type: file.type,
    }),
  });
  return (await rs.json()) as {
    name: string;
    signedUploadUrl: string;
  };
}

async function getPhotoInfo(name: string) {
  const token = await firebase.auth().currentUser?.getIdToken();
  const rs = await fetch(
    `${process.env.REACT_APP_API_URI}/photo/finish-upload/${name}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return (await rs.json()) as { url: string; width: number; height: number };
}

const limitFinish = pLimit(2);

function useUploadFile() {
  const { updateFileState } = useUploadFilesContext();

  const upload = useCallback(
    async (file: File) => {
      try {
        updateFileState(file, {
          state: "processing",
          step: "Đang chuẩn bị tải lên",
        });

        const { name, signedUploadUrl } = await getSignedUrl(file);

        updateFileState(file, {
          state: "processing",
          step: "Đang tải lên",
        });

        await axios.put(signedUploadUrl, new Blob([file]), {
          headers: {
            "Content-Type": file.type,
          },
          onUploadProgress(e) {
            updateFileState(file, {
              state: "processing",
              step: "Đang tải lên",
              progress: (e.loaded * 100) / e.total,
            });
          },
        });

        updateFileState(file, {
          state: "processing",
          step: "Đang đợi hoàn tất",
        });

        const { url, width, height } = await limitFinish(async () => {
          updateFileState(file, {
            state: "processing",
            step: "Đang hoàn tất",
          });

          return await getPhotoInfo(name);
        });

        updateFileState(file, { state: "success" });

        return {
          url,
          title: parseTitle(file.name),
          width,
          height,
        };
      } catch (ex) {
        console.log(ex);
        updateFileState(file, { state: "error" });
      }
    },
    [updateFileState]
  );

  return { updateFileState, upload };
}

export default useUploadFile;
