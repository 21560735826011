import CoffeeCup from "../../components/Lotties/CoffeeCup";
import styles from "./OutputFormat.module.scss";

type OutputFormatProps = {
  loading?: boolean;
  progress?: false | React.ReactElement;
  onDownload(format: string, resolution: string): void;
  onCancel(): void;
};

const OutputFormat = ({
  loading = false,
  progress,
  onDownload,
  onCancel,
}: OutputFormatProps) => {
  return (
    <div className="backdrop" onClick={onCancel}>
      <div className={styles.root} onClick={(e) => e.stopPropagation()}>
        <h2>Kết xuất hình ảnh</h2>
        {loading && (
          <div>
            <CoffeeCup />
            {progress && <div className={styles.progress}>{progress}</div>}
          </div>
        )}
        {!loading && (
          <>
            <label>
              Lựa chọn định dạng để tải file hoặc bỏ qua nếu không muốn tải
            </label>
            <div className={styles.options}>
              <div className="buttons">
                <button
                  onClick={() => onDownload("image/png", "1080")}
                  className="primary button"
                >
                  PNG - 1080p
                </button>
                <button
                  onClick={() => onDownload("image/jpeg", "1080")}
                  className="primary button"
                >
                  JPG/JPEG - 1080p
                </button>
                <button
                  onClick={() => onDownload("image/webp", "1080")}
                  className="primary button"
                >
                  WEBP - 1080p
                </button>
              </div>
              <div className={styles.desc}>
                (Mạng xã hội: Facebook, Zalo...)
              </div>
            </div>
            <div className={styles.options}>
              <div className="buttons">
                <button
                  onClick={() => onDownload("image/png", "2048")}
                  className="primary button"
                >
                  PNG - 2K
                </button>
                <button
                  onClick={() => onDownload("image/jpeg", "2048")}
                  className="primary button"
                >
                  JPG/JPEG - 2K
                </button>
                <button
                  onClick={() => onDownload("image/webp", "2048")}
                  className="primary button"
                >
                  WEBP - 2K
                </button>
              </div>
              <div className={styles.desc}>
                (Khổ in lớn, TV độ phân giải cao)
              </div>
            </div>
            <div className={styles.footer}>
              <button onClick={onCancel} className="error-invert border button">
                Bỏ qua, không tải về
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default OutputFormat;
