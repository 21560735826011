import qs from "querystring";
import pMemoize from "p-memoize";
import { getDirectoryHandle } from "../hooks/useLocalFolder";

export type Options = {
  resolution?: "h_160" | "1080" | "2048" | "original";
};

export function getPhotoUrl(url: string, options?: Options) {
  const query = qs.stringify(options);

  if (url.startsWith("blob:")) {
    return url;
  }

  return url + (query ? "?" + query : "");
}

export function fitContainer(component: Size, container: Size) {
  const w_ratio = container.width / component.width;
  const h_ratio = container.height / component.height;
  const ratio = Math.min(w_ratio, h_ratio);

  return {
    width: Math.round(component.width * ratio),
    height: Math.round(component.height * ratio),
  };
}

export const getImageSize = pMemoize(
  (url: string) =>
    new Promise<{ width: number; height: number } | null>((resolve, reject) => {
      const image = new Image();
      image.src = url;
      image.onload = () => {
        resolve({ width: image.width, height: image.height });
        image.remove();
      };
      image.onerror = (e) => {
        reject();
        image.remove();
      };
    })
);

export async function resolvePhotoUrl(
  folderId: string | undefined,
  url: string,
  resolution: Options["resolution"]
) {
  if (url.startsWith("local:")) {
    const dirHandle = await getDirectoryHandle(folderId);
    if (dirHandle) {
      const fileHandle = await dirHandle.getFileHandle(url.slice(6));
      return URL.createObjectURL(await fileHandle?.getFile());
    }
  }

  return getPhotoUrl(url, { resolution });
}

export async function resolvePhotoUrlAsBase64(
  folderId: string | undefined,
  url: string,
  resolution: Options["resolution"]
) {
  if (url.startsWith("local:")) {
    const dirHandle = await getDirectoryHandle(folderId);
    if (dirHandle) {
      const fileHandle = await dirHandle.getFileHandle(url.slice(6));
      const file = await fileHandle.getFile();
      const buffer = await file.arrayBuffer();
      const source = Buffer.from(buffer).toString("base64");
      return `data:${file.type};base64,${source}`;
    }
  }

  return getPhotoUrl(url, { resolution });
}

export async function loadImage(url: string) {
  return new Promise<HTMLImageElement>(async (resolve, reject) => {
    const image = new Image();
    image.src = url;
    image.onerror = () => {
      reject();
    };

    image.onload = async () => {
      resolve(image);
    };
  });
}

export async function blobFromCanvas(canvas: HTMLCanvasElement) {
  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (!blob) {
        reject();
        return;
      }

      resolve(blob);
      return;
    });
  });
}
