import frame_yody_2 from "./frame_yody_2";
import logo_yody from "./logo_yody";
import sample from "../assets/sample.jpeg";
import khung_1 from "./khung_1";
import bg_2 from "./bg_2";
import bg_1 from "./bg_1";

const resources: Record<string, string> = {
  logo_yody,
  frame_yody_2,
  sample,
  khung_1,
  bg_1,
  bg_2,
};

export default resources;
