import { useState, useCallback, useRef } from "react";
import cx from "classnames";
import {
  Upload_GetFiles,
  Upload_GetFilesDocument,
  Upload_GetFilesQueryResult,
  Upload_GetFilesVariables,
  useCreateFile,
} from "../../graphql-generated";
import styles from "./UploadDrawer.module.scss";
import useUploadFile from "./useUploadFile";
import { useApolloClient } from "@apollo/client";
import Summary from "./Summary";
import CloseIcon from "../../components/Icons/CloseIcon";
import UploadIcon from "../../components/Icons/UploadIcon";
import pLimit from "p-limit";
import { useUploadFilesContext } from "../../hooks/useUploadFilesContext";

const limitUpload = pLimit(4);

const UploadDrawer = () => {
  const [tab, setTab] = useState<"remaining" | "all">("remaining");
  const [createFile] = useCreateFile();
  const refStopping = useRef(false);
  const [uploadState, setUploadState] = useState<"ready" | "uploading">(
    "ready"
  );
  const {
    folderId,
    filesState,
    openFiles,
    open,
    expand,
    collapse,
    updateFileState,
  } = useUploadFilesContext();
  const { upload } = useUploadFile();
  const client = useApolloClient();

  const uploadSingleFile = useCallback(
    async (file: File, uploadedFiles: Upload_GetFiles["files"]) => {
      try {
        const existed = uploadedFiles.some((f) => f.name === file.name);

        if (existed) {
          updateFileState(file, { state: "duplicated" });
          return;
        }

        const uploadedFile: ThenArg<ReturnType<typeof upload>> =
          await limitUpload(async () => {
            if (refStopping.current) {
              return;
            }
            return await upload(file);
          });

        if (uploadedFile) {
          const { url, title, width, height } = uploadedFile;
          await createFile({
            variables: {
              file: {
                name: file.name,
                type: file.type,
                parent_id: folderId,
                url,
                meta: {
                  width,
                  height,
                  title,
                },
              },
            },
          });
        }
      } catch (ex) {
        //
        console.log(ex);
      }
    },
    [createFile, folderId, updateFileState, upload]
  );

  const handleUploadClick = useCallback(async () => {
    collapse();
    if (uploadState === "ready") {
      refStopping.current = false;
      setUploadState("uploading");

      const { data } = await client.query<
        Upload_GetFilesQueryResult["data"],
        Upload_GetFilesVariables
      >({
        query: Upload_GetFilesDocument,
        variables: {
          filter: { parent_id: { _eq: folderId } },
        },
        fetchPolicy: "network-only",
      });

      const uploadedFiles = data?.files || [];

      await Promise.all(
        filesState.map(({ file }) => uploadSingleFile(file, uploadedFiles))
      );

      refStopping.current = false;
      setUploadState("ready");
    }
  }, [client, collapse, filesState, folderId, uploadSingleFile, uploadState]);

  const handleStopClick = useCallback(() => {
    refStopping.current = true;
  }, []);

  if (filesState.length === 0) {
    return null;
  }

  if (!open) {
    return (
      <button
        className={cx(styles.floatButton, {
          ring: uploadState === "uploading",
        })}
        onClick={expand}
      >
        <UploadIcon />
        <div className={styles.label}>
          {filesState.filter((f) => f.state === "remaining").length}
        </div>
      </button>
    );
  }

  const stillProcessing = filesState.some(
    (f) => f.state === "processing" || f.state === "remaining"
  );

  return (
    <div className="backdrop" onClick={collapse}>
      <div
        className={styles.root}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <div className={styles.head}>
          <div>
            <h2>Upload</h2>
            <small>Tổng số {filesState.length} ảnh</small>
          </div>
          <button
            className="button secondary"
            onClick={() => {
              if (uploadState === "uploading") {
                collapse();
              } else {
                openFiles(undefined, []);
              }
            }}
          >
            <CloseIcon />
          </button>
        </div>

        <Summary />

        <div className="tabs">
          <button
            className={cx("tab", { active: tab === "remaining" })}
            onClick={() => setTab("remaining")}
          >
            Chờ xử lý
          </button>
          <button
            className={cx("tab", { active: tab === "all" })}
            onClick={() => setTab("all")}
          >
            Toàn bộ
          </button>
          <div className="flex-spacer" />
          <div className={styles.tool}>
            {uploadState === "ready" && (
              <button className="button primary" onClick={handleUploadClick}>
                Tải lên
              </button>
            )}
            {uploadState === "uploading" && !refStopping.current && (
              <button className="button error" onClick={handleStopClick}>
                Dừng tải lên
              </button>
            )}
            {refStopping.current && <div>Đang dừng tải lên...</div>}
          </div>
        </div>

        {tab === "remaining" && !stillProcessing && (
          <div className={styles.pendingEmpty}>
            <h3>Đã xử lý tất cả ảnh cần tải lên!</h3>
          </div>
        )}

        <div className={styles.files}>
          {filesState
            .filter((fileState) => {
              if (tab === "remaining") {
                return (
                  fileState.state === "remaining" ||
                  fileState.state === "processing"
                );
              }

              return true;
            })
            .map((fileState) => {
              return (
                <div
                  className={styles.file}
                  key={fileState.file.webkitRelativePath}
                >
                  {fileState.file.name}

                  <label className={cx(styles[fileState?.state as string])}>
                    {fileState?.state}
                  </label>
                  {fileState?.step && (
                    <span className={styles.uploadState}>
                      {fileState.step}
                      {fileState?.progress
                        ? ` (${fileState.progress.toFixed(1)}%)`
                        : ""}
                      ...
                    </span>
                  )}
                  <div className={styles.progress}>
                    <div style={{ width: fileState?.progress + "%" }} />
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default UploadDrawer;
