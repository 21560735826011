import get from "lodash/get";
import { useCallback, useEffect, useMemo, useState } from "react";
import traverse from "traverse";
import merge from "lodash/merge";
import omit from "lodash/omit";
import resources from "../resources";
import { getImageSize } from "../utils/imageUtils";
import { Hooks_GetTheme } from "../graphql-generated";

type SVGReplace = Record<string, string | Array<string>>;

export type DraggableImageAlign =
  | "top-left"
  | "top-right"
  | "bottom-left"
  | "bottom-right";

type SVGDraggableImageLayer = {
  type: "draggable-image";
  path: string;
  src: string;
  px: number;
  py: number;
  fit?: [number, number, number, number];
  align: DraggableImageAlign;
  ratio: number;
  svgReplace: SVGReplace;
};

type SVGLayer = { key: string } & (
  | SVGDraggableImageLayer
  | { type: "size"; src: string }
  | { type: "image"; src: string; ratio: number }
  | {
      type: "text";
      path: string;
      text: string;
      ratio: number;
      vAlign: "top" | "bottom";
      anchorAlign: "inside" | "outside";
      anchor: string;
    }
);

type IResolvedLayer = {
  originalWidth: number;
  originalHeight: number;
};

export type SVGResolvedLayer = SVGLayer & IResolvedLayer;
export type SVGDraggableImageResolvedLayer = SVGDraggableImageLayer &
  IResolvedLayer;

type SVGLoaderProps = {
  url: string;
  meta?: any;
  theme?: Hooks_GetTheme["theme"];
};

function updateLayers<T>(layers: T, data: any): T {
  return traverse(layers || []).map(
    // eslint-disable-next-line array-callback-return
    function (value) {
      // prettier-ignore
      const isVariable = typeof value === "string" && value.startsWith("$");
      if (isVariable) {
        this.update(get(data, value.slice(1)));
      }
    }
  );
}

function pluginReplaceSVG(src: string, data: SVGReplace): string {
  for (const name in data) {
    const value = data[name];
    src = src.replace(
      new RegExp(name, "gi"),
      typeof value === "string" ? value : value.join("")
    );
  }

  return src;
}

function pluginCorrectSVG(src: string): string {
  src = src.replace(/<rect id="replace_picture" [^>]+\/>/, "");

  if (/^\s*<svg/.test(src)) {
    src = src.replace(
      /xlink:href="data:img\/png/g,
      `xlink:href="data:image/png`
    );
  }

  return src;
}

const DEFAULT_LAYERS: Array<SVGResolvedLayer> = [];

type LoadSVGCallbacks = {
  setSize?(size: Size | null): void;
};

export async function loadSVG(
  url: string,
  theme: Hooks_GetTheme["theme"],
  meta: any,
  callbacks?: LoadSVGCallbacks
) {
  try {
    if (theme && url) {
      const layers = updateLayers(
        (theme.layers || []) as Array<SVGLayer>,
        merge(omit(theme.meta || {}, ["layers"]), {
          url,
          ...meta,
        })
      );

      let size: Size | null = null;
      const resolvedLayers = await Promise.all(
        layers.map(async (layer) => {
          if ("src" in layer) {
            let src = resources[layer.src] || layer.src;
            src = pluginCorrectSVG(src);

            if ("svgReplace" in layer) {
              src = pluginReplaceSVG(src, layer.svgReplace);
            }

            if (/^\s*<svg/.test(src)) {
              src = src.replace(
                /xlink:href="data:img\/png/g,
                `xlink:href="data:image/png`
              );

              const base64 = Buffer.from(src).toString("base64");
              src = `data:image/svg+xml;base64,${base64}`;
            }

            let layerSize = await getImageSize(src);
            if (layerSize && layer.type === "image" && layer.ratio) {
              layerSize = {
                width: layerSize.width * layer.ratio,
                height: layerSize.height * layer.ratio,
              };
            }

            if (layer.type === "size") {
              size = layerSize;
              callbacks?.setSize && callbacks.setSize(layerSize);
            }

            if (layerSize) {
              return {
                ...layer,
                src,
                originalWidth: layerSize.width,
                originalHeight: layerSize.height,
              };
            }
          }

          return { ...layer, originalWidth: 0, originalHeight: 0 };
        })
      );

      const sizeLayer = resolvedLayers.find((data) => data.type === "size");
      const originalWidth = sizeLayer?.originalWidth || 140;
      const originalHeight = sizeLayer?.originalHeight || 140;
      const calculateMeta = {};

      if (originalWidth && originalHeight) {
        for (let layer of resolvedLayers) {
          if (layer && layer.type === "draggable-image" && layer.fit) {
            if (layer.originalWidth && layer.originalHeight) {
              if (!layer.ratio) {
                const [x1, y1, x2, y2] = layer.fit;
                const width = x2 - x1;
                const height = y2 - y1;
                const rw = width / layer.originalWidth;
                const rh = height / layer.originalHeight;
                const scale = Math.max(rw, rh);

                const scaleW = scale * layer.originalWidth;
                const scaleH = scale * layer.originalHeight;

                const onePxRatio = Math.max(
                  width / originalWidth,
                  height / originalHeight
                );

                const ratio = Math.ceil(
                  Math.max(
                    (scaleW * onePxRatio) / originalWidth,
                    (scaleH * onePxRatio) / originalHeight
                  ) * 100
                );

                const px = Math.round(
                  (x1 - (scaleW - width) / 2 / originalWidth) * 100
                );
                const py = Math.round(
                  (y1 - (scaleH - height) / 2 / originalHeight) * 100
                );

                if (layer.path) {
                  Object.assign(calculateMeta, {
                    [`${layer.path}Size`]: ratio,
                    [`${layer.path}Position_x`]: px,
                    [`${layer.path}Position_y`]: py,
                  });
                }
                Object.assign(layer, { ratio, px, py });
              }
            }
          }
        }
      }

      return {
        layers: resolvedLayers,
        size: size as Size | null,
        calculateMeta,
      };
    }
  } catch (ex) {
    //
  }
  return { layers: [], size: null };
}

const useSVGLoader = ({ theme, url, meta = null }: SVGLoaderProps) => {
  const [size, setSize] = useState<Size | null>(null);
  const [loading, setLoading] = useState(true);
  const [layers, setLayers] = useState<Array<SVGResolvedLayer>>([]);
  const [calculateMeta, setCalculateMeta] = useState<any>({});

  const update = useCallback(async (theme, url, meta) => {
    if (!theme) {
      return;
    }
    setLoading(true);
    const { layers, calculateMeta } = await loadSVG(url, theme, meta, {
      setSize,
    });
    setLayers(layers);
    setCalculateMeta(calculateMeta);
    setLoading(false);
  }, []);

  useEffect(() => {
    update(theme, url, meta);
  }, [update, theme, url, meta]);

  return useMemo(
    () => ({
      loading,
      size,
      layers: layers || DEFAULT_LAYERS,
      calculateMeta: calculateMeta,
      update,
    }),
    [update, loading, size, layers, calculateMeta]
  );
};

export default useSVGLoader;
