import { Link } from "react-router-dom";
import ClearSelectionIcon from "../../components/Icons/ClearSelectionIcon";
import CloseIcon from "../../components/Icons/CloseIcon";
import EyeIcon from "../../components/Icons/EyeIcon";
import SelectAllIcon from "../../components/Icons/SelectAllIcon";
import { File } from "../../graphql-generated";
import { SelectionProps } from "../../hooks/useSelection";
import styles from "./SelectionBar.module.scss";

type SelectionBarProps = {
  files: Array<Pick<File, "id">>;
  backetButton?: boolean;
  selection: SelectionProps;
  onClear(): void;
  onSelectAll(): void;
  onClose(): void;
};

const SelectionBar = ({
  files,
  selection,
  backetButton = true,
  onClear,
  onSelectAll,
  onClose,
}: SelectionBarProps) => {
  if (!selection.selecting) {
    return null;
  }

  const areAllSelected = files.every((file) =>
    selection.fileIds.includes(file.id)
  );

  return (
    <div className={styles.root}>
      <div className={styles.summary}>
        Đã chọn {selection.fileIds.length} ảnh
      </div>
      <div className="flex-spacer" />

      <div className="buttons">
        {selection.fileIds.length > 0 && (
          <button className={styles.clearSelection} onClick={onClear}>
            <ClearSelectionIcon />
            Chọn lại
          </button>
        )}
        {!areAllSelected && (
          <button className={styles.selectAll} onClick={onSelectAll}>
            <SelectAllIcon />
            Chọn tất cả ảnh
          </button>
        )}
        {selection.fileIds.length > 0 && (
          <>
            {backetButton && (
              <Link className={styles.view} to="/basket">
                <EyeIcon /> Xem ảnh đã chọn
              </Link>
            )}
            <button className={styles.close} onClick={onClose}>
              <CloseIcon />
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default SelectionBar;
