import cx from "classnames";
import { FileListItem } from "../../graphql-generated";
import { SelectionProps } from "../../hooks/useSelection";
import File from "./File";
import styles from "./Files.module.css";

type FilesProps = {
  className?: string;
  files: Array<FileListItem>;
  selection?: SelectionProps;
  onToggleSelection?(id: string): void;
};

const Files = ({
  className,
  files,
  selection,
  onToggleSelection,
}: FilesProps) => {
  return (
    <div className={cx(styles.files, className)}>
      {files.map((file) => (
        <File
          key={file.id}
          file={file}
          selection={selection}
          onToggleSelection={onToggleSelection}
        />
      ))}
    </div>
  );
};

export default Files;
