import { useFormik } from "formik";
import { useCreateFolder } from "../../graphql-generated";
import { useDialogState } from "../../hooks/useDialog";
import styles from "./CreateFolderModal.module.scss";

const CreateFolderModal = () => {
  const { close } = useDialogState();
  const [createFolder] = useCreateFolder({
    refetchQueries: ["getFolder"],
  });

  const form = useFormik({
    initialValues: {
      name: "",
    },
    validate: async (values) => {
      if (!values.name) {
        return {
          name: "Tên bộ sưu tập không được để trống",
        };
      }
    },
    onSubmit: async (values) => {
      try {
        await createFolder({
          variables: values,
          refetchQueries: ["getFolders"],
        });
        close();
      } catch (ex) {
        //
      }
    },
  });

  return (
    <div className="backdrop" onClick={close}>
      <div
        className={styles.root}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
          <h2>Thêm bộ sưu tập</h2>
        <div className={styles.body}>
          <div className="form-item">
            <label>Tên bộ sưu tập</label>
            <input
              className="full-w"
              type="text"
              autoComplete="off"
              required
              placeholder="Nhập tên bộ sưu tập"
              name="name"
              value={form.values.name}
              onChange={form.handleChange}
            />
            {form.errors.name && (
              <span className="error-invert">{form.errors.name}</span>
            )}
          </div>
        </div>
        <div className={styles.footer}>
          <button className={"button secondary"} onClick={close}>
            Đóng lại
          </button>
          <button
            className={"button primary"}
            onClick={() => form.handleSubmit()}
          >
            Tạo thư mục
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateFolderModal;
