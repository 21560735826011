import { fitContainer } from "../../utils/imageUtils";
import styles from "./File.module.scss";
import { SVGViewer } from "../../containers/SVGEditor";
import usePhotoPreviewUrl from "../../hooks/usePhotoPreviewUrl";
import useSVGLoader from "../../hooks/useSVGLoader";
import useGetTheme from "../../hooks/useGetTheme";
import { FileListItem } from "../../graphql-generated";

type FileProps = {
  file: FileListItem;
  onToggleSelection?(fileId: string): void;
  onClick(): void;
};

const File = ({ file, onClick }: FileProps) => {
  const url = usePhotoPreviewUrl(file);
  const { theme } = useGetTheme(file.theme_id);
  const preview = useSVGLoader({
    theme,
    url,
    meta: file.meta,
  });

  if (!preview.size || !url) {
    return null;
  }

  return (
    <div className={styles.file} onClick={onClick}>
      <SVGViewer
        layers={preview.layers}
        size={fitContainer(preview.size, {
          height: 90,
          width: Number.MAX_SAFE_INTEGER,
        })}
        originalSize={preview.size}
      />
    </div>
  );
};

export default File;
