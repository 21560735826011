import { SVGResolvedLayer } from "../../hooks/useSVGLoader";
import SVGEditorBase from "./SVGEditorBase";

type SVGViewerProps = {
  size: Size;
  originalSize: Size;
  layers: Array<SVGResolvedLayer>;
  onClick?(): void;
};

const SVGViewer = ({
  size,
  originalSize,
  layers,
  onClick,
}: SVGViewerProps) => {
  return (
    <SVGEditorBase
      size={size}
      originalSize={originalSize}
      layers={layers}
      mouse={{ dx: 0, dy: 0, draggingObject: "" }}
      onClick={onClick}
    />
  );
};

export default SVGViewer;
