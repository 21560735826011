import React, { MouseEventHandler } from "react";
import {
  MouseState,
  mouseToPxPy,
  percentToXY,
} from "../../hooks/useDragObject";
import { SVGResolvedLayer } from "../../hooks/useSVGLoader";
import styles from "./styles";

type SVGEditorProps = {
  size: Size;
  originalSize: Size;
  layers: Array<SVGResolvedLayer>;
  mouse: MouseState;
  onClick?(): void;
  onDraggingObject?: MouseEventHandler;
  onDragObjectStart?(e: React.MouseEvent, name: string): void;
  onResize?(key: string, delta: number): void;
};

const XDdiv = (p: any) => {
  const { children, ...props } = p;
  return React.createElement("xhtml:div", props, children);
};

const SVGEditorBase = ({
  size: { width, height },
  originalSize,
  layers,
  mouse,
  onClick,
  onDraggingObject,
  onDragObjectStart,
  onResize,
}: SVGEditorProps) => {
  // const svgSize = fitContainer(
  //   { width: originalSize.width, height: originalSize.height },
  //   { width, height }
  // );
  // const zoom = originalSize.width / svgSize.width;
  // const { editingLayers, mouse, handleDragObject, startDragObject } =
  //   useDragObject(initLayers, {
  //     ...originalSize,
  //     zoom,
  //     onMove,
  //   });

  const baseFont = Math.max(originalSize.width / 75, originalSize.height / 66);

  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      //@ts-ignore
      width={width}
      height={height}
      viewBox={`0 0 ${originalSize.width} ${originalSize.height}`}
      onMouseMove={onDraggingObject}
      onClick={onClick}
      {...{ "xmlns:xhtml": "http://www.w3.org/1999/xhtml" }}
    >
      {layers.map((layer) => {
        switch (layer.type) {
          case "image":
            return (
              <image
                style={{ pointerEvents: "none" }}
                key={layer.key}
                href={layer.src}
                x={0}
                y={0}
                width={layer.originalWidth}
                height={layer.originalHeight}
              />
            );
          case "text": {
            const anchorLayer = layers.find((l) => l.key === layer.anchor);

            if (!anchorLayer) {
              return null;
            }

            let position = {
              x: 0,
              y: 0,
              ...originalSize,
            };

            if (anchorLayer.type === "draggable-image") {
              const mousePosition = mouseToPxPy(
                originalSize,
                anchorLayer,
                mouse
              );
              position = percentToXY(
                originalSize,
                anchorLayer,
                Math.round(anchorLayer.px - mousePosition.px),
                Math.round(anchorLayer.py - mousePosition.py)
              );

              if (!position) {
                return null;
              }
            }

            let x = 0;
            let y = 0;
            let text = "";

            if (layer.anchorAlign === "outside") {
              text = layer.text
                .split(/,\s*/g)
                .map(
                  (text) =>
                    `<xhtml:div style="display: block;">${text}</xhtml:div>`
                )
                .join("");
              x = position.x;

              if (
                anchorLayer?.type === "draggable-image" &&
                anchorLayer.align.includes("right")
              ) {
                x = position.x - originalSize.width + position.width;
              }

              if (layer.vAlign === "top") {
                y = position.y - originalSize.height;
              }

              if (layer.vAlign === "bottom") {
                y = position.y + position.height;
              }
            }

            if (layer.anchorAlign === "inside") {
              text = layer.text.split(/,\s*/g).join(", ");
            }

            return (
              <foreignObject
                key={layer.key}
                x={x}
                y={y}
                width={originalSize.width}
                height={originalSize.height}
              >
                <XDdiv
                  style={{
                    fontSize: baseFont,
                    lineHeight: `${baseFont * 1.1}px`,
                    ...originalSize,
                    ...styles.title,
                    ...styles[layer.anchorAlign],
                    ...styles[layer.anchorAlign + "_" + layer.vAlign],
                    ...(anchorLayer.type === "draggable-image" &&
                      styles["anchor_" + anchorLayer?.align]),
                  }}
                >
                  <XDdiv
                    style={{
                      fontSize: (baseFont * layer.ratio) / 100,
                      lineHeight: `${((baseFont * layer.ratio) / 100) * 1.1}px`,
                      ...styles[layer.anchorAlign + " > div"],
                      ...(anchorLayer.type === "draggable-image" &&
                        styles["anchor_" + anchorLayer?.align + " > div"]),
                    }}
                    onWheel={(e: any) => {
                      if (onResize) {
                        e.stopPropagation();
                        onResize(
                          layer.path,
                          layer.ratio +
                            Math.round(e.deltaY / Math.abs(e.deltaY))
                        );
                      }
                      return false;
                    }}
                    dangerouslySetInnerHTML={{
                      __html: text,
                    }}
                  />
                </XDdiv>
              </foreignObject>
            );
          }
          case "draggable-image": {
            const mousePosition = mouseToPxPy(originalSize, layer, mouse);
            const position = percentToXY(
              originalSize,
              layer,
              Math.round(layer.px - mousePosition.px),
              Math.round(layer.py - mousePosition.py)
            );

            if (!position) {
              return null;
            }

            return (
              <React.Fragment key={layer.key}>
                <image
                  x={position.x}
                  y={position.y}
                  width={position.width}
                  onMouseDown={(e) => {
                    onDragObjectStart && onDragObjectStart(e, layer.key);
                  }}
                  onWheel={(e) => {
                    e.stopPropagation();
                    if (onResize) {
                      onResize(
                        layer.path,
                        layer.ratio +
                          Math.round(e.deltaY / Math.abs(e.deltaY)) / 1
                      );
                    }
                    return false;
                  }}
                  href={layer.src}
                />
              </React.Fragment>
            );
          }
        }

        return null;
      })}
    </svg>
  );
};

export default SVGEditorBase;
