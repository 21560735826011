import { useCallback } from "react";
import { createStateContext } from "react-use";

export type UploadState =
  | "remaining"
  | "processing"
  | "success"
  | "error"
  | "duplicated";

export type FileUploadState = {
  file: File;
  state: UploadState;
  step?: string;
  progress?: number;
};

export type FilesUploadState = Array<FileUploadState>;
const [useUploadFilesStateInternal, UploadFilesStateProvider] =
  createStateContext<{
    open: boolean;
    folderId?: string | null;
    filesState: FilesUploadState;
  }>({ open: false, filesState: [] });

const useUploadFilesContext = () => {
  const [uploadFilesState, setUploadFilesState] = useUploadFilesStateInternal();

  const openFiles = useCallback(
    (folderId: string | undefined | null, files: Array<File>) => {
      setUploadFilesState({
        open: true,
        folderId,
        filesState: files.map((file) => ({
          file,
          state: "remaining",
        })),
      });
    },
    [setUploadFilesState]
  );

  const expand = useCallback(() => {
    setUploadFilesState((state) => ({
      ...state,
      open: true,
    }));
  }, [setUploadFilesState]);

  const collapse = useCallback(() => {
    setUploadFilesState((state) => ({
      ...state,
      open: false,
    }));
  }, [setUploadFilesState]);

  const updateFileState = useCallback(
    (file, nextState: Omit<FilesUploadState[0], "file">) => {
      setUploadFilesState((state) => ({
        ...state,
        filesState: state.filesState.map((f) =>
          f.file === file ? { file, ...nextState } : f
        ),
      }));
    },
    [setUploadFilesState]
  );

  return { ...uploadFilesState, openFiles, expand, collapse, updateFileState };
};

export { useUploadFilesContext };
export default UploadFilesStateProvider;
