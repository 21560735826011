import { useHistory } from "react-router-dom";
import OpenFolderIcon from "../../components/Icons/OpenFolderIcon";
import { getIndexDb } from "../../config";
import { Folder } from "../../graphql-generated";
import styles from "./OpenLocalFolder.module.scss";
import useLocalFolder, { LocalFolder } from "./useLocalFolder";

const PROJECT_FILE = ".magikpic.proj";

type ProjectFileContent = {
  id: string;
};

async function openProjectFile(
  dirHandle: FileSystemDirectoryHandle
): Promise<ProjectFileContent> {
  const fileHandle = await dirHandle.getFileHandle(PROJECT_FILE);
  const file = await fileHandle.getFile();
  return JSON.parse(await file.text());
}

async function createProjectFile(
  dirHandle: FileSystemDirectoryHandle,
  id: string
): Promise<Pick<Folder, "id">> {
  const file = await dirHandle.getFileHandle(PROJECT_FILE, {
    create: true,
  });
  const writer = await file.createWritable();
  writer.write(JSON.stringify({ id }));
  writer.close();

  return { id };
}

const OpenLocalFolder = () => {
  const history = useHistory();
  const { open } = useLocalFolder();
  return (
    <div
      className={styles.folder}
      onClick={async () => {
        const dirHandle = await window.showDirectoryPicker();
        const db = await getIndexDb();

        let folder: LocalFolder;
        try {
          let fileContent: ProjectFileContent;
          fileContent = await openProjectFile(dirHandle);
          folder = (await open(dirHandle, fileContent.id))!;
        } catch (ex) {
          folder = (await open(dirHandle))!;
          await createProjectFile(dirHandle, folder.id);
        }

        await db.put("folderHandlers", dirHandle, folder.id);
        history.push(`/local-folder/${folder.id}`);
      }}
    >
      <div className={styles.openLocal}>
        <OpenFolderIcon />
        Mở thư mục
      </div>
    </div>
  );
};

export default OpenLocalFolder;
