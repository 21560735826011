import { SVGViewer } from "../SVGEditor";
import { Hooks_GetTheme } from "../../graphql-generated";
import { fitContainer } from "../../utils/imageUtils";
import styles from "./ThemeItem.module.scss";
import CheckIcon from "../../components/Icons/CheckIcon";
import useSVGLoader from "../../hooks/useSVGLoader";

type ThemSwitchButtonProps = {
  theme: Hooks_GetTheme["theme"];
  selected?: boolean;
  onClick(): void;
};

const ThemeItem = ({ theme, selected, onClick }: ThemSwitchButtonProps) => {
  const themePreview = useSVGLoader({
    theme,
    meta: theme?.meta,
    url: "sample",
  });

  if (!themePreview.size) {
    return null;
  }

  return (
    <div className={styles.theme}>
      <SVGViewer
        onClick={onClick}
        size={fitContainer(themePreview.size, {
          width: Number.MAX_SAFE_INTEGER,
          height: 150,
        })}
        originalSize={themePreview.size}
        layers={themePreview.layers}
      />
      {selected && (
        <div className={styles.switchIcon}>
          <CheckIcon />
        </div>
      )}
    </div>
  );
};

export default ThemeItem;
