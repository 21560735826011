import { FormikProps } from "formik";
import get from "lodash/get";
import styles from "./SmartCoord.module.scss";

type SmartCoordProps = {
  labelX: string;
  labelY: string;
  path: string;
  form: FormikProps<any>;
};

const SmartCoord = ({ labelX, labelY, path, form }: SmartCoordProps) => (
  <div className={styles.position}>
    <div className="form-item">
      <label>{labelX}</label>
      <input
        className="full-w"
        name={`${path}_x`}
        type="number"
        step={1}
        value={get(form.values, path + "_x")}
        onChange={form.handleChange}
      />
    </div>
    <div className="form-item">
      <label>{labelY}</label>
      <input
        className="full-w"
        name={`${path}_y`}
        type="number"
        step={1}
        value={get(form.values, path + "_y")}
        onChange={form.handleChange}
      />
    </div>
  </div>
);

export default SmartCoord;
