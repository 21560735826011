import { useFormik } from "formik";
import cx from "classnames";
import styles from "./QRDataDialog.module.scss";

type QRDataDialogProps = {
  onDownload(data: Array<string>): void;
  onCancel(): void;
};

/*
https://facebook.com
https://google.com
https://facebook.com
*/

const QRDataDialog = ({ onDownload, onCancel }: QRDataDialogProps) => {
  const form = useFormik({
    initialValues: {
      qrData: "",
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      let data = values.qrData
        .split(/[\r\n]+/g)
        .filter((s) => s && !/^\s+$/.test(s));
      data = Array.from(new Set(data));
      onDownload(data);
    },
  });
  return (
    <div className="backdrop" onClick={onCancel}>
      <div className={styles.root} onClick={(e) => e.stopPropagation()}>
        <h2>Nhập danh sách mã QR</h2>
        <label>Nhập danh sách mã QR cần tạo, mỗi dòng một mã QR</label>
        <div>
          <div className="form-item">
            <label>Danh sách mã QR</label>
            <textarea
              className="full-w"
              name="qrData"
              rows={10}
              onChange={form.handleChange}
              value={form.values.qrData}
            />
          </div>
          <div className={styles.tooltip}>
            <strong>
              Mỗi mã QR nằm trên một dòng, có thể có tên file hoặc không.
              <br />
              Định dạng mã QR:
            </strong>
            <br />
            ten_file1|lien_ket_qr1
            <br />
            lien_ket_qr2
            <br />
            ...
          </div>
        </div>
        <div className={cx(styles.footer, "buttons")}>
          <button onClick={onCancel} className="error-invert border button">
            Đóng lại
          </button>
          <button
            type="submit"
            onClick={() => form.handleSubmit()}
            className="primary border button"
          >
            Tiếp tục
          </button>
        </div>
      </div>
    </div>
  );
};

export default QRDataDialog;
