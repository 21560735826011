import { useCallback } from "react";
import { createStateContext } from "react-use";
import pMemoize from "p-memoize";
import { getIndexDb } from "../config";

export type DirectoryHandleState = "openning" | "asking" | "ready" | "denied";
export type DirectoryHandleContext = {
  dirHandle: FileSystemDirectoryHandle | null;
  state: DirectoryHandleState;
};

const [useLocalFolderContextInternal, LocalFolderProvider] =
  createStateContext<DirectoryHandleContext>({
    dirHandle: null,
    state: "openning",
  });

export const getDirectoryHandle = pMemoize(
  async (folderId: string | null | undefined) => {
    if (!folderId) {
      return null;
    }
    const db = await getIndexDb();
    return (await db.get("folderHandlers", folderId)) || null;
  }
);

export const useLocalFolderContext = () => {
  const [localFolderState, setLocalFolderState] =
    useLocalFolderContextInternal();

  const loadLocalFolder = useCallback(
    async (folderId: string) => {
      try {
        const dirHandle = await getDirectoryHandle(folderId);

        if (!dirHandle) {
          setLocalFolderState({ dirHandle: null, state: "ready" });
          return;
        }

        if (dirHandle) {
          try {
            const queryResult = await dirHandle.queryPermission({
              mode: "readwrite",
            });

            if (queryResult === "granted") {
              setLocalFolderState({ dirHandle, state: "ready" });
              return;
            }

            setLocalFolderState({ dirHandle, state: "asking" });
            const requestResult = await dirHandle.requestPermission({
              mode: "readwrite",
            });

            if (requestResult === "granted") {
              setLocalFolderState({ dirHandle, state: "ready" });
              return;
            }

            setLocalFolderState({ dirHandle, state: "denied" });
          } catch (ex) {
            setLocalFolderState({ dirHandle, state: "denied" });
          }
        }
      } catch (ex) {
        //
      }
    },
    [setLocalFolderState]
  );

  return { ...localFolderState, loadLocalFolder };
};
export default LocalFolderProvider;
