import { useHistory } from "react-router";
import { useLocation } from "react-use";
import qs from "qs";
import { Files } from "../../containers/Files";
import { Header } from "../../containers/Header";
import styles from "./FilesScreen.module.css";
import { useSelection, useSelectionContext } from "../../hooks/useSelection";
import BackIcon2 from "../../components/Icons/BackIcon2";
import CloseIcon from "../../components/Icons/CloseIcon";
import { useSearchRemoteFiles } from "../../graphql-generated";
import { EMPTY_ARRAY } from "../../utils/constants";
import { SelectionBar } from "../../containers/SelectionBar";

const FilesScreen = () => {
  const history = useHistory();
  const location = useLocation();
  const { selection, select, clearSelection, hide, show, toggle } =
    useSelection(useSelectionContext());
  const keyword =
    qs.parse((location.search || "").slice(1))?.keyword || ("" as string);
  const { data } = useSearchRemoteFiles({
    variables: {
      keyword: "%" + keyword + "%",
    },
  });

  const files = data?.files || EMPTY_ARRAY;

  return (
    <div>
      <Header selection={selection} />
      <div className={styles.body}>
        <div className={styles.section}>
          <div className="flex-lr">
            {keyword ? (
              <h2>
                <ul className="breadcrumb">
                  <li>Tìm kiếm</li>
                  <li>Từ khoá: {keyword}</li>
                </ul>
              </h2>
            ) : (
              <h2>Tất cả ảnh</h2>
            )}
            <div className="buttons">
              {selection.selecting && (
                <button className={styles.close} onClick={hide}>
                  <CloseIcon /> Đã chọn xong ảnh
                </button>
              )}
              {!selection.selecting && (
                <>
                  <button className={styles.back} onClick={history.goBack}>
                    <BackIcon2 /> Quay lại
                  </button>
                  <button className="primary button" onClick={show}>
                    Chọn ảnh
                  </button>
                </>
              )}
            </div>
          </div>
          <Files
            files={files}
            selection={selection}
            onToggleSelection={toggle}
          />
          <SelectionBar
            files={files}
            selection={selection}
            onClose={hide}
            onClear={clearSelection}
            onSelectAll={() => select(files.map((f) => f.id))}
          />
        </div>
      </div>
    </div>
  );
};

export default FilesScreen;
