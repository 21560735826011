import { useHistory, useRouteMatch } from "react-router";
import cx from "classnames";
import { MouseEvent, useCallback, useState } from "react";
import { fitContainer } from "../../utils/imageUtils";
import styles from "./File.module.scss";
import { SelectionProps } from "../../hooks/useSelection";
import DownloadIcon from "../../components/Icons/DownloadIcon";
import { OutputFormat } from "../OutputFormat";
import { SVGViewer } from "../SVGEditor";
import usePhotoPreviewUrl from "../../hooks/usePhotoPreviewUrl";
import useSVGLoader from "../../hooks/useSVGLoader";
import useGetTheme from "../../hooks/useGetTheme";
import { FileListItem } from "../../graphql-generated";
import useDownloadFile, {
  getDownloadFileName,
} from "../../hooks/useDownloadFile";

type FileProps = {
  file: FileListItem;
  selection?: SelectionProps;
  onToggleSelection?(fileId: string): void;
};

const File = ({ file, selection, onToggleSelection }: FileProps) => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const match = useRouteMatch<{ folderId: string }>();

  const url = usePhotoPreviewUrl(file);
  const { theme } = useGetTheme(file.theme_id);
  const preview = useSVGLoader({
    theme,
    url,
    meta: file.meta,
  });
  const folderId = match.params.folderId;

  const { toCanvas, writeToFile, loading: downloading } = useDownloadFile();

  const handleToggleSelection = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      if (onToggleSelection) {
        onToggleSelection(file.id);
      }
    },
    [onToggleSelection, file]
  );

  const handleClick = useCallback(() => {
    if (!selection?.selecting || !onToggleSelection) {
      history.push(`/file/${file.id}`);
    } else {
      onToggleSelection(file.id);
    }
  }, [file.id, history, onToggleSelection, selection]);

  const selecting = !!selection?.selecting;
  const selected = !!selection?.fileIds.includes(file.id);

  if (!preview.size || !url) {
    return null;
  }

  return (
    <>
      <div
        className={cx(styles.file, { selecting, selected })}
        onClick={handleClick}
      >
        <SVGViewer
          layers={preview.layers}
          size={fitContainer(preview.size, {
            height: 140,
            width: Number.MAX_SAFE_INTEGER,
          })}
          originalSize={preview.size}
        />
        {selecting && (
          <button
            className={cx(styles.select, { selected })}
            onClick={handleToggleSelection}
          >
            &nbsp;
          </button>
        )}
        {!selecting && (
          <button
            className={styles.download}
            onClick={(e) => {
              e.stopPropagation();
              setOpen(true);
            }}
          >
            <DownloadIcon /> Tải về
          </button>
        )}
      </div>
      {open && (
        <OutputFormat
          loading={downloading}
          onCancel={() => setOpen(false)}
          onDownload={async (format, resolution) => {
            const { canvas, cleanUp } = await toCanvas(file, resolution);
            if (canvas) {
              const name = getDownloadFileName(file.name, format);
              await writeToFile(canvas, folderId, name, format);
              cleanUp();
            }
            setOpen(false);
          }}
        />
      )}
    </>
  );
};

export default File;
