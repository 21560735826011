import { FormikProps } from "formik";
import { FileListItem } from "../../graphql-generated";
import usePhotoPreviewUrl from "../../hooks/usePhotoPreviewUrl";
import useSVGLoader from "../../hooks/useSVGLoader";
import { DEFAULT_THEME } from "../../utils/constants";
import { fitContainer } from "../../utils/imageUtils";
import { SVGViewer } from "../SVGEditor";
import styles from "./EditFilesModal.module.scss";

type RowProps = {
  file: FileListItem;
  form: Pick<FormikProps<any>, "values" | "handleChange">;
  rowIndex: number;
};

const Row = ({ form, file, rowIndex }: RowProps) => {
  const url = usePhotoPreviewUrl(file);
  const preview = useSVGLoader({
    url,
    theme: DEFAULT_THEME,
    meta: file.meta,
  });

  if (!preview.size) {
    return null;
  }

  return (
    <div key={file.id} className={styles.file}>
      <div className={styles.row}>
        <div>
          <SVGViewer
            size={fitContainer(preview.size, { width: 90, height: 60 })}
            layers={preview.layers}
            originalSize={preview.size}
          />
        </div>
        <div>
          <div className={styles.formRow}>
            <div className={styles.name}>
              <label>Tên sản phẩm</label>
              <input
                name={`${rowIndex}.name`}
                value={form.values[rowIndex].name}
                onChange={form.handleChange}
              />
            </div>
            <div className={styles.price}>
              <label>Giá bán (nghìn đồng)</label>
              <input
                name={`${rowIndex}.price`}
                value={form.values[rowIndex].price}
                onChange={form.handleChange}
              />
            </div>
            <div className={styles.desc}>
              <label>Chất liệu, công năng</label>
              <input
                name={`${rowIndex}.description`}
                value={form.values[rowIndex].description}
                onChange={form.handleChange}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Row;
