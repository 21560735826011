export function isFileSupported(name: string) {
  return /\.(jpg|jpeg|png)$/i.test(name);
}

export function parseTitle(name: string) {
  return name
    .split(",")
    .map((name) => {
      const m = /^[^.]+/.exec(name);
      return m && m[0];
    })
    .filter((line) => !!line)
    .map((line) => line!.replace(/[ ]*\([^(]+$/g, ""))
    .join(",");
}