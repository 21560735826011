import React, {
  MouseEventHandler,
  useState,
  useCallback,
  useEffect,
} from "react";
import { useEvent } from "react-use";
import { fitContainer } from "../utils/imageUtils";
import {
  SVGResolvedLayer,
  SVGDraggableImageResolvedLayer,
} from "./useSVGLoader";

export type MouseState = {
  dx: number;
  dy: number;
  draggingObject: string | null;
};

export type LayerMoveHandler = (path: string, px: number, py: number) => void;

type UseDragObjectOptions = {
  width: number;
  height: number;
  zoom: number;
  onMove?: LayerMoveHandler;
};

export const percentToXY = (
  { width, height }: Size,
  layer: SVGDraggableImageResolvedLayer,
  px: number,
  py: number
) => {
  const deltaX = layer.align.includes("left") ? 1 : -1;
  const deltaY = layer.align.includes("top") ? 1 : -1;

  const size = fitContainer(
    {
      width: layer.originalWidth,
      height: layer.originalHeight,
    },
    {
      width: (layer.ratio / 100) * width,
      height: (layer.ratio / 100) * height,
    }
  );

  return {
    x:
      ((1 - deltaX) / 2 + (deltaX * px) / 100) * width -
      ((1 - deltaX) * size.width) / 2,
    y:
      ((1 - deltaY) / 2 + (deltaY * py) / 100) * height -
      ((1 - deltaY) * size.height) / 2,
    ...size,
  };
};

export const mouseToPxPy = (
  { width, height }: Size,
  layer: SVGResolvedLayer,
  mouse: Pick<MouseState, "dx" | "dy" | "draggingObject">
) => {
  return mouse.draggingObject === layer.key
    ? {
        px: (mouse.dx * 100) / width,
        py: (mouse.dy * 100) / height,
      }
    : { px: 0, py: 0 };
};

const useDragObject = (
  initLayers: Array<SVGResolvedLayer>,
  { width, height, zoom, onMove }: UseDragObjectOptions
) => {
  const [layers, setLayers] = useState<Array<SVGResolvedLayer>>(initLayers);

  useEffect(() => {
    setLayers(initLayers);
  }, [initLayers]);

  const [mouse, setMouse] = useState<MouseState & { x: number; y: number }>({
    x: 0,
    y: 0,
    dx: 0,
    dy: 0,
    draggingObject: null,
  });

  useEvent(
    "mouseup",
    () => {
      const { draggingObject } = mouse;
      if (draggingObject) {
        const layer = layers.find((pos) => pos.key === mouse.draggingObject);
        if (layer && layer.type === "draggable-image") {
          if (onMove) {
            onMove(
              layer.path,
              Math.round(layer.px - (mouse.dx * 100) / width),
              Math.round(layer.py - (mouse.dy * 100) / height)
            );
          }
        }

        setMouse({ ...mouse, dx: 0, dy: 0, draggingObject: null });
      }
    },
    global.document.body
  );

  const handleDraggingObject = useCallback<MouseEventHandler>(
    (e) => {
      e.stopPropagation();
      if (mouse.draggingObject) {
        const layer = layers.find((pos) => pos.key === mouse.draggingObject);
        if (layer && layer.type === "draggable-image") {
          const deltaX = layer.align.includes("left") ? -1 : 1;
          const deltaY = layer.align.includes("top") ? -1 : 1;
          setMouse((mouse) => ({
            ...mouse,
            dx: (e.pageX - mouse.x) * zoom * deltaX,
            dy: (e.pageY - mouse.y) * zoom * deltaY,
          }));
        }
      }
    },
    [layers, mouse.draggingObject, zoom]
  );

  const handleDragObjectStart = useCallback(
    (e: React.MouseEvent, name: string) => {
      setMouse({
        x: e.pageX,
        y: e.pageY,
        dx: 0,
        dy: 0,
        draggingObject: name,
      });
    },
    []
  );

  return {
    mouse,
    editingLayers: layers,
    handleDraggingObject,
    handleDragObjectStart,
    setConfigurations: setLayers,
  };
};

export default useDragObject;
