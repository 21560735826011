export const DEFAULT_THEME = Object.freeze({
  id: "",
  name: "",
  slug: "",
  layers: [
    {
      key: "size",
      src: "$url",
      type: "size",
    },
    {
      key: "photo",
      src: "$url",
      type: "image",
    },
  ],
  meta: {},
  form_settings: [],
});

export const EMPTY_ARRAY = Object.freeze([]) as any as Array<any>;
