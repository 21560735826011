const DownloadIcon = () => (
  <svg id="Layer_1" viewBox="0 0 120.59 122.88">
    <path
      fill="currentColor"
      d="M0,0H120.59V93.72H87.84V83.64H109.5V10.08H11.1V83.64H32.2V93.72H0V0ZM51.64,101.3V83.63H68.51V101.3H80.73L60.31,122.88,39.85,101.3ZM33.92,24.93a7.84,7.84,0,1,1-7.85,7.84,7.85,7.85,0,0,1,7.85-7.84Zm33,33.66L82.62,31.46,99.29,73.62H21.5V68.39L28,68.07l6.53-16,3.27,11.44h9.8l8.5-21.89,10.79,17Z"
    />
  </svg>
);

export default DownloadIcon;
