import Thumbnails from "../../components/Lotties/Thumbnails";
import styles from "./Progress.module.scss";

type ProgressProps = {
  current: number;
  total: number;
};

const Progress = ({ current, total }: ProgressProps) => {
  return (
    <div className={styles.root}>
      <div>
        <Thumbnails />
      </div>
      <div className={styles.intro}>
        <h2>
          {current} / {total}
        </h2>
        <label>Đang tạo hình ảnh xem trước...</label>
        <div className={styles.help}>
          Các hành ảnh xem trước giúp cho ứng dụng hiển thị nhanh và mượt hơn
          nhờ sử dụng ít tài nguyên của hệ thống.
        </div>
        <p className={styles.help}>
          Sau khi hoàn tất, ứng dụng sẽ sử dụng lại dữ liệu này trong lần mở thư
          mục kế tiếp.
        </p>
      </div>
    </div>
  );
};

export default Progress;
