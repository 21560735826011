import { useRef } from "react";
import { useUploadFilesContext } from "../../hooks/useUploadFilesContext";

type UploadButtonProps = {
  className?: string;
  children: React.ReactNode;
  folderId?: string;
};

const UploadButton = ({ children, folderId, className }: UploadButtonProps) => {
  const ref = useRef<HTMLInputElement>(null);
  const { filesState, openFiles } = useUploadFilesContext();

  if (filesState.length > 0) {
    return null;
  }

  return (
    <>
      <button className={className} onClick={() => ref && ref.current?.click()}>
        {children}
      </button>
      <input
        ref={ref}
        type="file"
        style={{ display: "none" }}
        // @ts-ignore
        webkitdirectory="true"
        mozdirectory="true"
        accept="image/*"
        onChange={async (e) => {
          const pickedFiles = e.target.files;
          if (pickedFiles) {
            const files = [];
            for (let i = 0; i < pickedFiles.length; i++) {
              const file = pickedFiles.item(i);
              if (
                file &&
                /\.(png|jpg|jpeg|web)$/.test(file.name.toLocaleLowerCase())
              ) {
                files.push(file);
              }
            }
            openFiles(folderId, files);
          }
        }}
      />
    </>
  );
};

export default UploadButton;
