import { CSSProperties } from "react";

const styles: Record<string, CSSProperties> = {
  title: {
    fontFamily: '"Avenir"',
    fontWeight: "bolder",
    display: "flex",
    width: "100%",
    height: "100%",
    textAlign: "right",
    userSelect: "none",
    color: "#000",
    WebkitFontSmoothing: "antialiased",
    MozOsxFontSmoothing: "grayscale",
    textRendering: "optimizeLegibility",
    textShadow: "0 0 15px #fff",
    justifyContent: "flex-end",
    boxSizing: "border-box",
  },
  outside: { padding: "0.2em 0.4em" },
  outside_top: { alignItems: "flex-end" },
  outside_bottom: { alignItems: "flex-start" },
  inside: { justifyContent: "stretch" },
  inside_top: { alignItems: "flex-start" },
  inside_bottom: { alignItems: "flex-end" },
  "inside > div": {
    width: "100%",
    textAlign: "center",
    backgroundColor: "#f8af19",
    padding: "0.6em 0.5em 0.45em 0.5em",
  },
  "anchor_bottom-left": { justifyContent: "flex-start" },
  "anchor_top-left": { justifyContent: "flex-start" },
  "anchor_top-left > div": { textAlign: "left" },
  "anchor_bottom-left > div": { textAlign: "left" },
};

export default styles;
