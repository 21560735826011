import React, { useCallback, useRef, useState } from "react";
import styles from "./ContextMenu.module.scss";
import MoreIcon from "../Icons/MoreIcon";
import { useClickAway } from "react-use";

type RenderProps = {
  close(): void;
};

type ContextMenuProps = {
  render: (props: RenderProps) => React.ReactNode;
};

const ContextMenu = ({ render }: ContextMenuProps) => {
  const ref = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);

  const close = useCallback(() => {
    setOpen(false);
  }, []);

  useClickAway(ref, () => {
    close();
  });
  return (
    <button ref={ref} className={styles.more} onClick={() => setOpen(!open)}>
      <MoreIcon />
      {open && (
        <div onClick={(e) => e.stopPropagation()} className={styles.menu}>
          {render({ close })}
        </div>
      )}
    </button>
  );
};

export default ContextMenu;
