import { useRouteMatch } from "react-router";
import styles from "./FileScreenLoader.module.scss";
import { useGetFile } from "../../graphql-generated";
import { useEffect, useState } from "react";
import { useLocalFolderContext } from "../../hooks/useLocalFolder";
import useGetTheme from "../../hooks/useGetTheme";
import FileScreenSkeleton from "./FileScreenSkeleton";
import { RequestPermission } from "../../containers/RequestPermission";
import Searching from "../../components/Lotties/Searching";
import FileScreen from "./FileScreen";

const FileScreenLoader = () => {
  const match = useRouteMatch<{ fileId: string }>();
  const fileId = match.params.fileId;
  const { loading, data } = useGetFile({
    variables: { id: fileId },
  });
  const file = data?.file || null;

  const [themeId, setThemeId] = useState("");
  const { loading: themeLoading, theme } = useGetTheme(themeId);

  const { state, loadLocalFolder } = useLocalFolderContext();

  useEffect(() => {
    if (file && file.parent_id) {
      loadLocalFolder(file.parent_id);
    }
  }, [file, loadLocalFolder]);

  useEffect(() => {
    setThemeId(file?.theme_id || "");
  }, [file?.theme_id]);

  if (loading || themeLoading || state === "openning") {
    return (
      <FileScreenSkeleton>
        <div className={styles.empty}>
          <Searching />
        </div>
      </FileScreenSkeleton>
    );
  }

  if (file && (state === "denied" || state === "asking")) {
    return (
      <FileScreenSkeleton>
        <div className={styles.empty}>
          <RequestPermission folderId={file.parent_id} />
        </div>
      </FileScreenSkeleton>
    );
  }

  if (!file || !theme) {
    return (
      <FileScreenSkeleton>
        <div className={styles.empty}>ERROR</div>
      </FileScreenSkeleton>
    );
  }

  return <FileScreen file={file} theme={theme} onThemeIdChange={setThemeId} />;
};

export default FileScreenLoader;
