import cx from "classnames";
import { useFormik } from "formik";
import jsQR from "jsqr";
import QRCode from "qrcode";
import merge from "lodash/merge";
import omit from "lodash/omit";
import pick from "lodash/pick";
import React, { useCallback, useMemo, useState } from "react";
import sanitize from "sanitize-filename";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { useDebounce, useWindowSize } from "react-use";
import BackIcon2 from "../../components/Icons/BackIcon2";
import DownloadIcon from "../../components/Icons/DownloadIcon";
import MoreIcon from "../../components/Icons/MoreIcon";
import QRIcon from "../../components/Icons/QRIcon";
import { OutputFormat } from "../../containers/OutputFormat";
import { useOutputState } from "../../containers/OutputFormat/useOutputState";
import { QRDataDialog } from "../../containers/QRDataDialog";
import { SVGEditor } from "../../containers/SVGEditor";
import {
  FileListItem,
  Hooks_GetTheme,
  useFilesByIds,
  useUpdateFileTheme,
} from "../../graphql-generated";
import useDownloadFile, {
  getDownloadFileName,
} from "../../hooks/useDownloadFile";
import useResolvedUrl from "../../hooks/usePhotoUrl";
import useSVGLoader from "../../hooks/useSVGLoader";
import { EMPTY_ARRAY } from "../../utils/constants";
import { fitContainer } from "../../utils/imageUtils";
import File from "./File";
import styles from "./FileScreen.module.scss";
import FileScreenSkeleton from "./FileScreenSkeleton";
import SmartCoord from "./SmartCoord";
import SmartDropdown from "./SmartDropdown";
import SmartSlider from "./SmartSlider";
import SmartTextArea from "./SmartTextArea";
import ThemeSwitchButton from "./ThemeSwitchButton";

type Theme = Hooks_GetTheme["theme"];
type FileScreenProps = {
  file: NonNullable<FileListItem>;
  theme: NonNullable<Theme>;
  onThemeIdChange(id: string): void;
};

const MAXIMUM_THUMBMAILS = 8;

const FileScreen = ({ file, theme, onThemeIdChange }: FileScreenProps) => {
  const history = useHistory();
  const location = useLocation();
  const { width, height } = useWindowSize();
  const { toCanvas, downloadFile, writeToFile } = useDownloadFile();
  const { name, processing, processed, updateProgress } = useOutputState();
  const [downloadQR, setDownloadQR] = useState<
    false | { x: number; y: number; w: number; h: number; res: number }
  >(false);
  const maxWidth = width - 450;
  const maxHeight = height - 200;
  const [updateFileTheme] = useUpdateFileTheme({
    refetchQueries: ["getFile"],
  });
  const [qrData, setQRData] = useState<Array<string>>([]);

  const url = useResolvedUrl(file.parent_id, file.url, {
    resolution: "1080",
  });

  const initFilePreview = useSVGLoader({
    theme,
    url,
  });

  const { data } = useFilesByIds({
    variables: {
      ids: location.state,
    },
    skip: !location.state,
  });

  const isMultiple = Array.isArray(location.state) && location.state.length > 1;

  const form = useFormik({
    initialValues: useMemo(
      () =>
        merge(
          omit(theme.meta || {}, ["url", "layers", "title"]),
          initFilePreview.calculateMeta,
          file.meta || {}
        ),
      [file.meta, theme, initFilePreview]
    ),
    enableReinitialize: true,
    onSubmit: async (values) => {
      const fields = (theme.form_settings || [])
        .map((s: any) => [
          s.fields
            .filter((f: any) => !isMultiple || !f.single)
            .map((f: any) => {
              if (f.type === "coord") {
                return [f.path + "_x", f.path + "_y"];
              }

              return [f.path];
            }),
        ])
        .flat(3);

      const updateFiles = files.length ? files : [file];

      for await (let f of updateFiles) {
        await updateFileTheme({
          variables: {
            id: f.id,
            themeId: theme.id,
            meta: Object.assign({}, f.meta, pick(values, fields)),
          },
        });
      }
    },
  });

  const filePreview = useSVGLoader({
    theme,
    meta: form.values,
    url,
  });

  const { setFieldValue } = form;

  const [open, setOpen] = useState<false | "output" | "qr">(false);

  const handleResize = useCallback(
    (key, ratio) => {
      const field = key + "Size";
      setFieldValue(field, ratio);
    },
    [setFieldValue]
  );

  const handleMove = useCallback(
    (key, px, py) => {
      const xField = key + "Position_x";
      const yField = key + "Position_y";
      setFieldValue(xField, px);
      setFieldValue(yField, py);
    },
    [setFieldValue]
  );

  const files: Array<FileListItem> = data?.files || EMPTY_ARRAY;

  const thumnails = useMemo(() => {
    return files.slice(0, MAXIMUM_THUMBMAILS).map((file) => (
      <File
        key={file.id}
        onClick={() => {
          history.replace(`/file/${file.id}`, location.state);
        }}
        file={{
          ...file,
          meta: { ...file.meta, ...form.initialValues },
          theme_id: theme.id,
        }}
      />
    ));
  }, [files, form.initialValues, history, location.state, theme.id]);

  const detectQR = useCallback(async () => {
    for (let res of ["256", "512", "1024", "2048"]) {
      const { canvas, cleanUp } = await toCanvas(
        {
          ...file,
          meta: { ...file.meta, ...form.values },
          theme_id: theme.id,
        },
        res
      );

      const context = canvas?.getContext("2d");

      if (canvas && context) {
        const imageData = context?.getImageData(
          0,
          0,
          canvas.width,
          canvas.height
        );
        if (imageData) {
          console.log(imageData.data.byteLength / canvas.width / canvas.height);
          const qr = await jsQR(imageData.data, canvas.width, canvas.height);
          if (qr) {
            const { topLeftCorner, bottomRightCorner } = qr.location;
            const { x, y } = topLeftCorner;
            const { x: x2, y: y2 } = bottomRightCorner;
            const w = x2 - x;
            const h = y2 - y;

            cleanUp();
            console.log({ x, y, w, h, res: parseFloat(res) });
            return { x, y, w, h, res: parseFloat(res) };
          }
        }
      }

      cleanUp();
    }

    return false;
  }, [file, form.values, theme.id, toCanvas]);

  useDebounce(
    async () => {
      const qr = await detectQR();
      if (qr) {
        setDownloadQR(qr);
      }
    },
    500,
    [form.values]
  );

  return (
    <>
      <FileScreenSkeleton>
        <div className={styles.main}>
          <button className={styles.back} onClick={() => history.goBack()}>
            <BackIcon2 />
          </button>
          <button
            className={styles.download}
            onClick={() => {
              setQRData([]);
              setOpen("output");
            }}
          >
            <DownloadIcon />
          </button>
          {downloadQR && (
            <button className={styles.downloadQR} onClick={() => setOpen("qr")}>
              <QRIcon />
            </button>
          )}
          <div className={styles.photo}>
            {filePreview.size && (
              <SVGEditor
                size={fitContainer(filePreview.size, {
                  width: maxWidth - 40,
                  height: maxHeight - 40,
                })}
                layers={filePreview.layers}
                originalSize={filePreview.size}
                onResize={handleResize}
                onMove={handleMove}
              />
            )}
          </div>
          <div className={styles.thumbs}>
            <div>
              {isMultiple && (
                <>
                  <h4>Ảnh đã chọn</h4>
                  <div className={styles.files}>
                    {thumnails}
                    {files.length > 10 && (
                      <div className={styles.moreFiles}>
                        <MoreIcon />
                        <label>+{files.length - MAXIMUM_THUMBMAILS}</label>
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
            <ThemeSwitchButton theme={theme} onThemeSwitch={onThemeIdChange} />
          </div>
        </div>
        <div className={cx(styles.parameters)}>
          {(theme.form_settings || []).map((section: any, index: number) => (
            <React.Fragment key={`section-${index}`}>
              {section.title && <h3>{section.title}</h3>}
              {(section.fields || [])
                .filter((f: any) => !isMultiple || !f.single)
                .map((f: any) => {
                  switch (f.type) {
                    case "slider":
                      return <SmartSlider key={f.path} {...f} form={form} />;
                    case "dropdown":
                      return <SmartDropdown key={f.path} {...f} form={form} />;
                    case "textarea":
                    case "text":
                      return <SmartTextArea key={f.path} {...f} form={form} />;
                    case "coord":
                      return <SmartCoord key={f.path} {...f} form={form} />;
                    default:
                      return null;
                  }
                })}
            </React.Fragment>
          ))}

          <div className="buttons pull-r full-w">
            <button className="primary button" onClick={form.submitForm}>
              Lưu lại
            </button>
          </div>
        </div>
      </FileScreenSkeleton>
      {open === "output" && (
        <OutputFormat
          loading={processing}
          progress={
            qrData.length > 0 && (
              <>
                <h3>
                  {processed}/{qrData.length}
                </h3>
                <p>
                  Đang kết xuất tới thư mục <u>out</u>
                </p>
                <p>{name}</p>
              </>
            )
          }
          onCancel={() => setOpen(false)}
          onDownload={async (format, resolution) => {
            const editingFile = {
              ...file,
              meta: { ...file.meta, ...form.values },
              theme_id: theme.id,
            };

            const { canvas, cleanUp } = await toCanvas(editingFile, resolution);
            if (canvas) {
              const context = canvas.getContext("2d");
              if (context && qrData.length > 0) {
                const imageData = context.getImageData(
                  0,
                  0,
                  canvas.width,
                  canvas.height
                );
                if (imageData && downloadQR) {
                  const name = getDownloadFileName(file.name, format);
                  const PADDING = 5;
                  const { x, y, w, h, res } = downloadQR;

                  const qrCanvas = document.createElement("canvas");
                  qrCanvas.width = w;
                  qrCanvas.height = h;

                  updateProgress({ processing: true });
                  let processed = 0;
                  for (let qr of qrData) {
                    const match = /^([^|]+)\|([^|]+)$/.exec(qr);
                    let nameWithQr = match
                      ? match[1]
                      : sanitize(qr, { replacement: () => "_" });
                    let link = match ? match[2] : qr;

                    updateProgress({ name: link, processed });
                    await QRCode.toCanvas(qrCanvas, link, {
                      margin: 0.25,
                    });

                    const r = parseFloat(resolution) / res;

                    context.drawImage(
                      qrCanvas,
                      (x - PADDING) * r,
                      (y - PADDING) * r,
                      (w + PADDING * 2) * r,
                      (h + PADDING * 2) * r
                    );

                    await writeToFile(
                      canvas,
                      file.parent_id,
                      nameWithQr + "-" + name,
                      format
                    );

                    processed++;
                  }
                }
                updateProgress({ processing: false });
              } else {
                downloadFile(canvas, file.parent_id, name, format);
              }
            }

            await cleanUp();
            setOpen(false);
          }}
        />
      )}
      {open === "qr" && (
        <QRDataDialog
          onCancel={() => setOpen(false)}
          onDownload={(values) => {
            if (values.length === 0) {
              setOpen(false);
            } else {
              setQRData(values);
              setOpen("output");
            }
          }}
        />
      )}
    </>
  );
};

export default FileScreen;
