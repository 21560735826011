import { useHistory } from "react-router";
import { useRouteMatch } from "react-router-dom";
import BackIcon from "../../components/Icons/BackIcon";
import Download from "../../containers/Downloads/Download";
import { Files } from "../../containers/Files";
import { Header } from "../../containers/Header";
import { useFilesByIds, useGetDownload } from "../../graphql-generated";
import { EMPTY_ARRAY } from "../../utils/constants";
import styles from "./DownloadedFilesScreen.module.css";

const DownloadedFilesScreen = () => {
  const match = useRouteMatch<{ downloadId: string }>();
  const { downloadId } = match.params;
  const history = useHistory();
  const { data, loading } = useGetDownload({
    variables: { id: downloadId },
  });

  const downloadData = (data?.download?.download_data || []) as DownloadData;
  const fileIds = downloadData.files.map((f) => f.id);
  const { loading: loadingFiles, data: dataFiles } = useFilesByIds({
    variables: {
      ids: fileIds,
    },
  });

  const files = dataFiles?.files || EMPTY_ARRAY;

  if (loading || loadingFiles) {
    return null;
  }

  return (
    <div>
      <Header />
      <div className={styles.body}>
        <div className={styles.section}>
          <div className="flex-lr">
            <h2>
              <ul className="breadcrumb">
                <li>Danh sách ảnh tải về</li>
                <li>{downloadId}</li>
              </ul>
            </h2>
            <div className="buttons">
              <button className={styles.back} onClick={history.goBack}>
                <BackIcon /> Quay lại
              </button>
            </div>
          </div>
          <Download download={data?.download} />
          <h2>Ảnh đã chọn để tải về</h2>
          <Files files={files} />
        </div>
      </div>
    </div>
  );
};

export default DownloadedFilesScreen;
