import pLimit from "p-limit";
import pMemoize from "p-memoize";
import Pica, { PicaResizeOptions } from "pica";
import { useEffect, useState } from "react";
import { useAsync } from "react-use";
import { getIndexDb } from "../config";
import { File as FileType } from "../graphql-generated";
import {
  getDirectoryHandle,
  useLocalFolderContext,
} from "../hooks/useLocalFolder";
import { fitContainer, getPhotoUrl, loadImage } from "../utils/imageUtils";
import { isFileSupported } from "../utils/utils";

export const hqLimit = pLimit(5);
(window as any).limit = hqLimit;

const qualityPica = Pica({
  features: ["js", "wasm", "ww"],
});

async function getThumbnailFromDb(key: string) {
  const db = await getIndexDb();
  const result = await db.get("thumbnails", key);
  return result;
}

async function putThumbnailToDb(key: string, data: string) {
  const db = await getIndexDb();
  await db.put("thumbnails", data, key);
}

function createThumbnailCanvas(
  pica: Pica.Pica,
  fileHandle: FileSystemFileHandle,
  options?: PicaResizeOptions
) {
  return new Promise<{
    width: number;
    height: number;
    canvas: HTMLCanvasElement;
  }>(async (resolve, reject) => {
    try {
      const file = await fileHandle.getFile();
      const url = URL.createObjectURL(file);

      const image = await loadImage(url);

      const canvas = document.createElement("canvas");
      const { width, height } = fitContainer(image, {
        width: 192,
        height: 192,
      });
      canvas.width = width;
      canvas.height = height;

      await pica.resize(image, canvas, options);

      resolve({ width, height, canvas });
      image.remove();
      URL.revokeObjectURL(url);
    } catch (ex) {
      reject(ex);
    }
  });
}

async function createHQThumbnail(
  fileHandle: FileSystemFileHandle
): Promise<string> {
  return hqLimit(async () => {
    const { canvas } = await createThumbnailCanvas(qualityPica, fileHandle);

    const result = await canvas.toDataURL();
    canvas.remove();

    return result;
  });
}

const buildLocalPreviewUrl = pMemoize(
  async function (folderId: string, fileName: string) {
    const dirHandle = await getDirectoryHandle(folderId);
    if (dirHandle) {
      const fileHandle = await dirHandle.getFileHandle(fileName);
      const hqKey = `${folderId}.${fileHandle.name}.hq`;
      let hqThumbnail = await getThumbnailFromDb(hqKey);

      if (!hqThumbnail) {
        hqThumbnail = await createHQThumbnail(fileHandle);
        await putThumbnailToDb(hqKey, hqThumbnail);
      }

      return hqThumbnail;
    }

    throw new Error("LOCAL_DIRECTORY_IS_NOT_READY");
  },
  { cacheKey: (args) => args.join(",") }
);

const usePhotoPreviewUrl = (file: Pick<FileType, "parent_id" | "url">) => {
  const { dirHandle } = useLocalFolderContext();
  const [url, setUrl] = useState("");

  useEffect(() => {
    (async function () {
      const url = file.url;
      if (url.startsWith("local:")) {
        if (!dirHandle || !file.parent_id || !window.showDirectoryPicker) {
          return;
        }

        const fileName = url.slice(6);
        setUrl(await buildLocalPreviewUrl(file.parent_id, fileName));
        return;
      }
      setUrl(getPhotoUrl(file.url, { resolution: "h_160" }));
    })();
  }, [dirHandle, file]);

  return url;
};

type DiectoryPreviewCallbacks = {
  setTotal: (value: number) => void;
  setCurrent: (value: number) => void;
};

const buildDirectoryPreviews = pMemoize(
  async (
    folderId: string,
    { setTotal, setCurrent }: DiectoryPreviewCallbacks
  ) => {
    const dirHandle = await getDirectoryHandle(folderId);

    if (dirHandle) {
      const files = [];
      for await (let fileHandle of dirHandle.values()) {
        if (fileHandle.kind === "file" && isFileSupported(fileHandle.name)) {
          files.push(fileHandle.name);
        }
      }
      setTotal(files.length);

      let current = 0;
      await Promise.all(
        files.map(async (fileName) => {
          await buildLocalPreviewUrl(folderId, fileName);
          current = current + 1;
          setCurrent(current);
        })
      );
    }
  }
);

export const useDirectoryPreview = (folderId: string) => {
  const { state } = useLocalFolderContext();
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(0);

  const { loading } = useAsync(async () => {
    if (state === "ready") {
      await buildDirectoryPreviews(folderId, {
        setTotal,
        setCurrent,
      });
    }
  }, [state, folderId]);

  return { total, current, loading };
};

export default usePhotoPreviewUrl;
