import { useState } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { ContextMenu } from "../../components/ContextMenu";
import BackIcon from "../../components/Icons/BackIcon";
import CloseIcon from "../../components/Icons/CloseIcon";
import { DownloadButton } from "../../containers/DownloadButton";
import { EditFilesModal } from "../../containers/EditFilesModal";
import { Files } from "../../containers/Files";
import { Header } from "../../containers/Header";
import { useFilesByIds } from "../../graphql-generated";
import { useSelection, useSelectionContext } from "../../hooks/useSelection";
import { EMPTY_ARRAY } from "../../utils/constants";
import styles from "./SelectedFilesScreen.module.css";

const SelectedFilesScreen = () => {
  const history = useHistory();
  const { selection, clearSelection } = useSelection(useSelectionContext());
  const [edit, setEdit] = useState(false);
  const { data } = useFilesByIds({
    variables: {
      ids: selection.fileIds,
    },
  });
  const files = data?.files || EMPTY_ARRAY;

  return (
    <div>
      <Header />
      <div className={styles.body}>
        <div className={styles.section}>
          <div className="flex-lr">
            <h2>Ảnh đã chọn</h2>
            <div className="buttons">
              <button className={styles.back} onClick={history.goBack}>
                <BackIcon /> Quay lại
              </button>
              <button
                className="button border error-invert"
                onClick={() => {
                  clearSelection();
                  history.replace("/");
                }}
              >
                <CloseIcon />
                Chọn lại
              </button>
              {/* TODO: todo */}
              <DownloadButton loading={true} onDownload={async () => {}} />
              <ContextMenu
                render={({ close }) => (
                  <>
                    <h3>Ảnh đã chọn</h3>
                    <ul>
                      <li
                        onClick={() => {
                          setEdit(true);
                          close();
                        }}
                      >
                        Cập nhật thông tin ảnh
                      </li>
                    </ul>
                  </>
                )}
              />
            </div>
          </div>

          {selection.fileIds.length === 0 && (
            <div className={styles.emptyState}>
              <h3>Chưa có ảnh nào được chọn, vui lòng chọn ảnh để tải.</h3>
              <Link to="/" className="primary bigger-button">
                Về trang chủ
              </Link>
            </div>
          )}
          <Files files={files} />
        </div>
      </div>
      {edit && (
        <EditFilesModal
          fileIds={selection.fileIds}
          onClose={() => setEdit(false)}
        />
      )}
    </div>
  );
};

export default SelectedFilesScreen;
