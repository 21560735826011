import { useState } from "react";
import { useHistory, useRouteMatch } from "react-router";
import { ContextMenu } from "../../components/ContextMenu";
import BackIcon2 from "../../components/Icons/BackIcon2";
import CloseIcon from "../../components/Icons/CloseIcon";
import DownloadIcon from "../../components/Icons/DownloadIcon";
import { EditFilesModal } from "../../containers/EditFilesModal";
import { Files } from "../../containers/Files";
import { Header } from "../../containers/Header";
import { OutputFormat } from "../../containers/OutputFormat";
import { RefreshButton } from "../../containers/RefreshButton";
import { SelectionBar } from "../../containers/SelectionBar";
import { UploadButton } from "../../containers/UploadButton";
import { useRemoteFolder } from "../../graphql-generated";
import { useSelection, useSelectionContext } from "../../hooks/useSelection";
import { EMPTY_ARRAY } from "../../utils/constants";
import styles from "./FolderScreen.module.scss";

const FolderScreen = () => {
  const history = useHistory();
  const match = useRouteMatch<{ folderId: string }>();
  const { selection, select, clearSelection, hide, show, toggle } =
    useSelection(useSelectionContext());
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const folderId = match.params.folderId;
  const { data, loading } = useRemoteFolder({
    variables: {
      id: folderId,
    },
  });

  if (loading) {
    return null;
  }

  const files = data?.folder?.files || EMPTY_ARRAY;

  if (!files.length) {
    return (
      <div>
        <Header selection={selection} />
        <div className={styles.body}>
          <div className={styles.title}>
            <h2>
              <ul className="breadcrumb">
                <li>Bộ sưu tập</li>
                <li>{data?.folder?.name}</li>
              </ul>
            </h2>
          </div>

          <div className={styles.emptyState}>
            <h3>Thư mục chưa có ảnh, hãy Tải ảnh lên để bắt đầu.</h3>
            <RefreshButton folderId={folderId} />
            <div className={styles.buttons}>
              <button className={styles.back} onClick={history.goBack}>
                <BackIcon2 /> Quay lại
              </button>
              <UploadButton className="primary button" folderId={folderId}>
                Tải ảnh lên
              </UploadButton>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const internalSelectedFileIds = files
    .map((f) => f.id)
    .filter((id) => selection.fileIds.includes(id));

  return (
    <div>
      <Header selection={selection} />
      <div className={styles.body}>
        <div className={styles.section}>
          <div className="flex-lr">
            <div className={styles.title}>
              <h2>
                <ul className="breadcrumb">
                  <li>Bộ sưu tập</li>
                  <li>{data?.folder?.name}</li>
                </ul>
              </h2>
            </div>
            <div className="flex-spacer">
              <RefreshButton folderId={folderId} />
            </div>
            <div className="buttons">
              {selection.selecting && (
                <>
                  <button className={styles.close} onClick={hide}>
                    <CloseIcon /> Đã chọn xong ảnh
                  </button>
                  {internalSelectedFileIds.length > 0 && (
                    <button
                      className="primary button"
                      onClick={async () => {
                        history.push(
                          `/file/${internalSelectedFileIds[0]}`,
                          internalSelectedFileIds
                        );
                      }}
                    >
                      <DownloadIcon /> Sửa ảnh đã chọn
                    </button>
                  )}
                </>
              )}
              {!selection.selecting && (
                <>
                  <button className={styles.back} onClick={history.goBack}>
                    <BackIcon2 /> Quay lại
                  </button>
                  <UploadButton
                    className="primary-invert button"
                    folderId={folderId}
                  >
                    Tải ảnh lên
                  </UploadButton>

                  <button className="primary button" onClick={show}>
                    Chọn ảnh
                  </button>
                  <ContextMenu
                    render={({ close }) => (
                      <>
                        <h3>Bộ sưu tập</h3>
                        <ul>
                          <li
                            onClick={() => {
                              setEdit(true);
                              close();
                            }}
                          >
                            Bổ sung thông tin ảnh
                          </li>
                          <li
                            onClick={() => {
                              setOpen(true);
                              close();
                            }}
                          >
                            Tải toàn bộ
                          </li>
                        </ul>
                      </>
                    )}
                  />
                </>
              )}
            </div>
          </div>
          <Files
            files={files}
            selection={selection}
            onToggleSelection={toggle}
          />
          {open && (
            <OutputFormat
              onCancel={() => setOpen(false)}
              onDownload={async (format, resolution) => {
                setOpen(false);
              }}
            />
          )}
        </div>
      </div>
      {edit && (
        <EditFilesModal
          fileIds={files.map((f) => f.id)}
          onClose={() => setEdit(false)}
        />
      )}
      <SelectionBar
        files={files}
        selection={selection}
        onClose={hide}
        onClear={clearSelection}
        onSelectAll={() => select(files.map((f) => f.id))}
      />
    </div>
  );
};

export default FolderScreen;
