import { useState } from "react";
import ReplaceIcon from "../../components/Icons/ReplaceIcon";
import { SVGViewer } from "../../containers/SVGEditor";
import { ThemeSwitchModal } from "../../containers/ThemeSwitchModal";
import { Hooks_GetTheme } from "../../graphql-generated";
import useSVGLoader from "../../hooks/useSVGLoader";
import { fitContainer } from "../../utils/imageUtils";
import styles from "./ThemeSwitchButton.module.scss";

type ThemSwitchButtonProps = {
  theme: Hooks_GetTheme["theme"];
  onThemeSwitch(themeId: string): void;
};

const ThemeSwitchButton = ({ theme, onThemeSwitch }: ThemSwitchButtonProps) => {
  const themePreview = useSVGLoader({
    theme,
    url: "sample",
  });
  const [open, setOpen] = useState(false);

  if (themePreview.loading || !themePreview.size) {
    return null;
  }

  return (
    <>
      <div className={styles.switchTheme}>
        <h4>Mẫu</h4>
        <div className={styles.theme}>
          {!themePreview.loading && themePreview.size && (
            <SVGViewer
              onClick={() => setOpen(true)}
              size={fitContainer(themePreview.size, {
                width: Number.MAX_SAFE_INTEGER,
                height: 90,
              })}
              originalSize={themePreview.size}
              layers={themePreview.layers}
            />
          )}
          <div className={styles.switchIcon}>
            <ReplaceIcon />
          </div>
        </div>
      </div>
      {open && (
        <ThemeSwitchModal
          selectedId={theme?.id}
          onClose={() => setOpen(false)}
          onThemeSwitch={(themeId) => {
            onThemeSwitch(themeId);
            setOpen(false);
          }}
        />
      )}
    </>
  );
};

export default ThemeSwitchButton;
