import { useLocalFolderContext } from "../../hooks/useLocalFolder";
import styles from "./RequestPermission.module.scss";

type RequestPermissionProps = {
  folderId: string;
};

const RequestPermission = ({ folderId }: RequestPermissionProps) => {
  const { dirHandle, state, loadLocalFolder } = useLocalFolderContext();

  return (
    <div className={styles.root}>
      <h2>Cấp quyền truy cập thư mục {dirHandle?.name}</h2>
      <p>
        Vui lòng cấp quyền truy cập thư mục cho Ứng dụng để có thể sử dụng tính năng
        này.
      </p>
      {state === "denied" && (
        <button
          className="button primary"
          onClick={() => loadLocalFolder(folderId)}
        >
          Cho phép truy cập
        </button>
      )}
      {state === "asking" && (
        <p className="primary-invert">Vui lòng xác nhận cho phép truy cập</p>
      )}
    </div>
  );
};

export default RequestPermission;
