import useDragObject, { LayerMoveHandler } from "../../hooks/useDragObject";
import { fitContainer } from "../../utils/imageUtils";
import { SVGResolvedLayer } from "../../hooks/useSVGLoader";
import SVGEditorBase from "./SVGEditorBase";

type SVGEditorProps = {
  size: Size;
  originalSize: Size;
  layers: Array<SVGResolvedLayer>;
  onMove?: LayerMoveHandler;
  onResize?(key: string, delta: number): void;
};

const SVGEditor = ({
  size,
  originalSize,
  layers: initLayers,
  onMove,
  onResize,
}: SVGEditorProps) => {
  const svgSize = fitContainer(originalSize, size);
  const zoom = originalSize.width / svgSize.width;
  const { editingLayers, mouse, handleDraggingObject, handleDragObjectStart } =
    useDragObject(initLayers, {
      ...originalSize,
      zoom,
      onMove,
    });

  return (
    <SVGEditorBase
      size={size}
      originalSize={originalSize}
      layers={editingLayers}
      mouse={mouse}
      onDragObjectStart={handleDragObjectStart}
      onDraggingObject={handleDraggingObject}
      onResize={onResize}
    />
  );
};

export default SVGEditor;
